import { useEffect, useRef, useState } from "react";
import VanillaTilt from "vanilla-tilt";
import { useNavigate } from "react-router-dom";
import { VolumeUp } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { PromotionButton } from "./style";
import useGameStore from "@src/zustand/game";
import useBaseStore from "@src/zustand/base";
import useSportStore from "@src/zustand/sport";
import useAuthStore from "@src/zustand/auth";

// Image
import SportPromotionImage from "@src/assets/images/promotion/sport.png";
import LivePromotionImage from "@src/assets/images/promotion/live.png";
import CasinoPromotionImage from "@src/assets/images/promotion/casino.png";
import PromotionImage from "@src/assets/images/promotion/promotion.png";
import MobilePromotionImage from "@src/assets/images/promotion/mobile_promotion.png";
import SkypeImage from "@src/assets/images/promotion/skype.png";
import MessageImage from "@src/assets/images/promotion/message.png";
import MailImage from "@src/assets/images/promotion/mail.png";
import FootballBanner from "@src/assets/images/promotion/sports.jpg";
import CasinoBanner from "@src/assets/images/promotion/e-games.jpg";
import LiveCasinoBanner from "@src/assets/images/promotion/live-casino.jpg";
import FishBanner from "@src/assets/images/promotion/fishing.jpg";
import RacingBanner from "@src/assets/images/promotion/racing.jpg";
import LotteryBanner from "@src/assets/images/promotion/lottery.jpg";

// Slider
import { Carousel } from "react-responsive-carousel";
import Marquee from "@src/configs/marquee";

//swiper
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";
import { categoryOptions } from "@src/configs/game";

// Swiper  End

interface CategoryBannerListType {
  img: string;
  url: string;
}
const Promotion = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 768px)");

  const tiltRefs = useRef<Array<Array<HTMLDivElement | null>>>([[], [], []]);

  useEffect(() => {
    tiltRefs.current.forEach((column) => {
      column.forEach((el) => {
        if (el) {
          VanillaTilt.init(el, {
            max: 25,
            speed: 400,
            glare: false,
            "max-glare": 0.3,
          });
        }
      });
    });

    // Cleanup function
    return () => {
      tiltRefs.current.forEach((column) => {
        column.forEach((el) => {
          if (el && (el as any).vanillaTilt) {
            (el as any).vanillaTilt.destroy();
          }
        });
      });
    };
  }, []);

  const categoryBannerList: CategoryBannerListType[][] = [
    [
      { img: FootballBanner, url: "/sport/list" },
      { img: LiveCasinoBanner, url: "/live-casino" },
    ],
    [
      { img: CasinoBanner, url: "/game/list" },
      { img: FishBanner, url: "/fishing" },
    ],
    [
      { img: RacingBanner, url: "/racing" },
      { img: LotteryBanner, url: "/lottery" },
    ],
  ];

  const categoryMobileBannerList: CategoryBannerListType[][] = [
    [
      { img: FootballBanner, url: "/sport/list" },
      { img: LiveCasinoBanner, url: "/live-casino" },
      { img: CasinoBanner, url: "/game/list" },
    ],
    [
      { img: FishBanner, url: "/fishing" },
      { img: RacingBanner, url: "/racing" },
      { img: LotteryBanner, url: "/lottery" },
    ],
  ];

  if (isMobile) {
    return (
      <Container>
        <Grid
          sx={{ background: theme.palette.background.paper }}
          width={"100%"}
          container
          spacing={2}
          mt={1}
          p="5px"
          ml={0}
          border={"1px solid"}
          borderColor={"border.default"}
          borderRadius={"50px"}
          height={"35px"}
        >
          <Grid
            item
            sx={{ paddingTop: "0 !important", paddingLeft: "1% !important" }}
            xs={12}
            display={"flex"}
            justifyContent={"start"}
            alignItems={"center"}
          >
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              bgcolor={theme.palette.text.secondary}
              borderRadius={"50px"}
              width={"24px"}
              height={"24px"}
            >
              <VolumeUp
                sx={{ color: theme.palette.background.default, height: "16px" }}
              />
            </Box>
            &nbsp; &nbsp;
            <Typography
              fontWeight={700}
              fontSize={"14px"}
              lineHeight={"15.24px"}
              color={theme.palette.text.secondary}
            >
              {t("Announcement")}
            </Typography>
          </Grid>
        </Grid>
        <Stack
          className="banner"
          position="relative"
          my={2}
          direction="row"
          spacing={2}
        >
          {categoryMobileBannerList.map((item, ind) => (
            <Stack direction="column" spacing={1}>
              {item.map((cItem, cInd) => (
                <Box
                  className="category-card "
                  onClick={() => navigate(cItem.url)}
                  key={cInd}
                  ref={(el) =>
                    (tiltRefs.current[ind][cInd] = el as HTMLDivElement)
                  }
                >
                  <Box
                    className="category-img"
                    borderRadius="8px"
                    width="100%"
                    component="img"
                    src={cItem.img}
                  />
                  {/* <Box className="category-content">
                  <Button className="category-btn">
                    <Typography>{t("Play Now")}</Typography>
                  </Button>
                </Box> */}
                </Box>
              ))}
            </Stack>
          ))}
        </Stack>
        {/* <Box mt={4}>
          <Carousel
            autoPlay={true}
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            showIndicators={false}
          >
            <Box>
              <img src={SportPromotionImage} />
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                mt={2}
              >
                <PromotionButton onClick={() => goPage("/sport/list")}>
                  {t("Sport")}
                </PromotionButton>
              </Box>
            </Box>
            <Box>
              <img src={LivePromotionImage} />
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                mt={2}
              >
                <PromotionButton onClick={() => goPage("/live-casino")}>
                  {t("Live Casino")}
                </PromotionButton>
              </Box>
            </Box>
            <Box>
              <img src={CasinoPromotionImage} />
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                mt={2}
              >
                <PromotionButton onClick={() => goPage("/game/list")}>
                  {t("Slots")}
                </PromotionButton>
              </Box>
            </Box>
          </Carousel>
        </Box>
        <Box mt={4}>
          <Typography
            mb={2}
            textTransform={"uppercase"}
            fontWeight={700}
            color={"text.promoTitle"}
            fontSize={"16px"}
            lineHeight={"20.32px"}
          >
            {t("New Member Promotion")}
          </Typography>
          <Box
            component="img"
            width={"100%"}
            borderRadius={"4px"}
            src={MobilePromotionImage}
          />
        </Box>
        <Box mt={4}>
          <Typography
            textTransform={"uppercase"}
            fontWeight={700}
            color={"text.promoTitle"}
            fontSize={"24px"}
          >
            {t("Member Support")}
          </Typography>
          <Stack spacing={4} direction={"row"} justifyContent={"space-between"}>
            <Box width={"72px"} height={"72px"}>
              <Box
                className="promotion-icon"
                component={"img"}
                src={MessageImage}
              />
              <Typography
                textAlign={"center"}
                lineHeight={"14.52px"}
                color={theme?.palette?.text?.secondary}
                fontSize={"12px"}
                fontWeight={400}
              >
                {t("Live Chat")}
              </Typography>
            </Box>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Box width={"72px"} height={"72px"}>
              <Box
                className="promotion-icon"
                component={"img"}
                src={SkypeImage}
              />
              <Typography
                textAlign={"center"}
                lineHeight={"14.52px"}
                color={theme?.palette?.text?.secondary}
                fontSize={"12px"}
                fontWeight={400}
              >
                {t("A1BET_CS")}
              </Typography>
            </Box>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Box width={"72px"} height={"72px"}>
              <Box
                className="promotion-icon"
                component={"img"}
                src={MailImage}
              />
              <Typography
                textAlign={"center"}
                lineHeight={"14.52px"}
                color={theme?.palette?.text?.secondary}
                fontSize={"12px"}
                fontWeight={400}
              >
                {t("cs@a1bet.cc")}
              </Typography>
            </Box>
          </Stack>
        </Box> */}
      </Container>
    );
  }

  return (
    <Container maxWidth="xl">
      <Grid
        sx={{ background: theme.palette.background.paper }}
        container
        spacing={2}
        mt={1}
        p="5px"
        border={"1px solid"}
        borderColor={"border.default"}
        borderRadius={"50px"}
        height={"40px"}
      >
        <Grid
          item
          sx={{ paddingTop: "0 !important", paddingLeft: "1% !important" }}
          xs={12}
          sm={12}
          md={2}
          display={"flex"}
          justifyContent={"start"}
          alignItems={"center"}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            bgcolor={theme.palette.text.secondary}
            borderRadius={"50px"}
            width={"24px"}
            height={"24px"}
          >
            <VolumeUp
              sx={{
                color: theme.palette.background.default,
                height: "20px",
                fontSize: "18px",
              }}
            />
          </Box>
          &nbsp; &nbsp;
          <Typography
            fontWeight={700}
            fontSize={"14px"}
            lineHeight={"15.24px"}
            color={theme.palette.text.secondary}
          >
            {t("Announcement")}
          </Typography>
        </Grid>
        <Grid
          item
          sx={{ paddingTop: "0 !important", paddingLeft: "1% !important" }}
          xs={12}
          sm={12}
          md={10}
          display={"flex"}
          alignItems={"center"}
        >
          <Marquee speed={40}>
            <Typography noWrap color={"text.default"} fontSize="14px">
              {t(
                "Lorem ipsum dolor sit amet consectetur. Morbi nec nulla diam ultricies. Quis ut sed nunc tempus egestas massa congue."
              )}
            </Typography>
          </Marquee>
        </Grid>
      </Grid>
      {/* <Stack
        mt={{ md: "50px", sm: "20px", xs: "20px" }}
        direction="column"
        spacing={2}
      >
        {categoryOptions.map((item) => (
          <Card
            sx={{
              background: "transparent",
            }}
          >
            <CardHeader
              avatar={
                <Box component="img" src={item.img} sx={{ width: "50px" }} />
              }
              title={
                <Typography
                  fontWeight={600}
                  fontSize={{ md: "24px", sm: "20px", xs: "20px" }}
                  color={theme.palette.text.secondary}
                  textAlign="left"
                >
                  {t(item.label)}
                </Typography>
              }
            />
            <CardContent>
              <Swiper
                slidesPerView={6}
                spaceBetween={5}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[Autoplay, Pagination]}
                className={`swiper-${item.label}`}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 5,
                    spaceBetween: 50,
                  },
                }}
              >
                {providers.map(
                  (pItem: providerItemType) =>
                    item.types.includes(pItem.type) && (
                      <SwiperSlide>
                        <Box
                          onClick={() => goPage(pItem.key, pItem.type)}
                          component="img"
                          src={`/provider/${pItem.key}.jpg`}
                          sx={{
                            borderRadius: "8px",
                            width: "80%",
                          }}
                        />
                      </SwiperSlide>
                    )
                )}
              </Swiper>
            </CardContent>
          </Card>
        ))}
      </Stack> */}

      <Stack
        position="relative"
        className="banner"
        my={2}
        direction="row"
        spacing={2}
      >
        {categoryBannerList.map((item, ind) => (
          <Stack direction="column" spacing={1} key={`category-group-${ind}`}>
            {item.map((cItem, cInd) => (
              <Box
                key={`category-item-${cInd}`}
                className="category-card"
                onClick={() => navigate(cItem.url)}
                ref={(el) =>
                  (tiltRefs.current[ind][cInd] = el as HTMLDivElement)
                }
              >
                <Box
                  className="category-img"
                  borderRadius="8px"
                  width="100%"
                  component="img"
                  src={cItem.img}
                />
                {/* <Box className="category-content">
                  <Button className="category-btn">
                    <Typography>{t("Play Now")}</Typography>
                  </Button>
                </Box> */}
              </Box>
            ))}
          </Stack>
        ))}
      </Stack>

      {/* <Grid container spacing={2} mt={2} height="40vh">
        <Grid item lg={6} md={12} sm={12}>
          <Box
            sx={{
              backgroundImage: `url(${FootballBanner})`,
              backgroundSize: "cover",
              height: "100%",
            }}
          ></Box>
        </Grid>
        <Grid item lg={6} md={12} sm={12}>
          <Box
            sx={{
              backgroundImage: `url(${CasinoBanner})`,
              backgroundSize: "cover",
              height: "100%",
            }}
          ></Box>
        </Grid>
        <Grid item lg={3} md={12} sm={12}>
          <Box
            sx={{
              backgroundImage: `url(${FishBanner})`,
              backgroundSize: "cover",
              height: "100%",
            }}
          ></Box>
        </Grid>
      </Grid> */}

      {/* <Grid container spacing={2} mt={2}>
        <Grid item xs={4}>
          <img src={SportPromotionImage} />
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            mt={2}
          >
            <PromotionButton onClick={() => goPage("/sport/list")}>
              {t("Sport")}
            </PromotionButton>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <img src={LivePromotionImage} />
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            mt={2}
          >
            <PromotionButton onClick={() => goPage("/live-casino")}>
              {t("Live Casino")}
            </PromotionButton>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <img src={CasinoPromotionImage} />
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            mt={2}
          >
            <PromotionButton onClick={() => goPage("/game/list")}>
              {t("Slots")}
            </PromotionButton>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} mt={2}>
        <Grid item xs={6}>
          <Typography
            textTransform={"uppercase"}
            fontWeight={700}
            color={"text.promoTitle"}
            fontSize={"24px"}
          >
            {t("New Member Promotion")}
          </Typography>
          <Box
            width={"100%"}
            component="img"
            src={PromotionImage}
            borderRadius={"4px"}
          />
        </Grid>
        <Grid item xs={3}>
          <Typography
            textTransform={"uppercase"}
            fontWeight={700}
            color={"text.promoTitle"}
            fontSize={"24px"}
          >
            {t("Product")}
          </Typography>
          <Box mt={2}>
            <Carousel
              autoPlay={true}
              showArrows={false}
              showStatus={false}
              showThumbs={false}
              showIndicators={false}
            >
              <Box>
                <Typography
                  textAlign={"left"}
                  mt={1}
                  textTransform={"uppercase"}
                  fontWeight={700}
                  color={"text.promoTitle"}
                  fontSize={"24px"}
                >
                  {t("Sports")}
                </Typography>
                <Typography
                  textAlign={"left"}
                  fontSize={"12px"}
                  sx={{ color: "text.default" }}
                  fontWeight={400}
                >
                  {t(
                    "Providing most popular and exciting events with competitive odds in market that will gives you rich gaming experience"
                  )}
                </Typography>
                <Typography
                  textAlign={"left"}
                  mt={1}
                  textTransform={"uppercase"}
                  fontWeight={700}
                  color={"text.promoTitle"}
                  fontSize={"24px"}
                >
                  {t("Live Casino")}
                </Typography>
                <Typography
                  textAlign={"left"}
                  fontSize={"12px"}
                  sx={{ color: "text.default" }}
                  fontWeight={400}
                >
                  {t(
                    "Multiple live dealer tables for interactive entertainment that you may choose from variety of games such as Barccarat, Dragon Tiger. Sic Bo, Roulette & many more"
                  )}
                </Typography>
                <Typography
                  textAlign={"left"}
                  mt={1}
                  textTransform={"uppercase"}
                  fontWeight={700}
                  color={"text.promoTitle"}
                  fontSize={"24px"}
                >
                  {t("Slots")}
                </Typography>
                <Typography
                  textAlign={"left"}
                  fontSize={"12px"}
                  sx={{ color: "text.default" }}
                  fontWeight={400}
                >
                  {t(
                    "Provides variety of classic slot games with huge chances of free games with big points & winnings are waiting for you"
                  )}
                </Typography>
              </Box>
              <Box>
                <Typography
                  textAlign={"left"}
                  mt={1}
                  textTransform={"uppercase"}
                  fontWeight={700}
                  color={"text.promoTitle"}
                  fontSize={"24px"}
                >
                  {t("Sports")}
                </Typography>
                <Typography
                  textAlign={"left"}
                  fontSize={"12px"}
                  sx={{ color: "text.default" }}
                  fontWeight={400}
                >
                  {t(
                    "Providing most popular and exciting events with competitive odds in market that will gives you rich gaming experience"
                  )}
                </Typography>
                <Typography
                  textAlign={"left"}
                  mt={1}
                  textTransform={"uppercase"}
                  fontWeight={700}
                  color={"text.promoTitle"}
                  fontSize={"24px"}
                >
                  {t("Live Casino")}
                </Typography>
                <Typography
                  textAlign={"left"}
                  fontSize={"12px"}
                  sx={{ color: "text.default" }}
                  fontWeight={400}
                >
                  {t(
                    "Multiple live dealer tables for interactive entertainment that you may choose from variety of games such as Barccarat, Dragon Tiger. Sic Bo, Roulette & many more"
                  )}
                </Typography>
                <Typography
                  textAlign={"left"}
                  mt={1}
                  textTransform={"uppercase"}
                  fontWeight={700}
                  color={"text.promoTitle"}
                  fontSize={"24px"}
                >
                  {t("Slots")}
                </Typography>
                <Typography
                  textAlign={"left"}
                  fontSize={"12px"}
                  sx={{ color: "text.default" }}
                  fontWeight={400}
                >
                  {t(
                    "Provides variety of classic slot games with huge chances of free games with big points & winnings are waiting for you"
                  )}
                </Typography>
              </Box>
            </Carousel>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Typography
            textTransform={"uppercase"}
            fontWeight={700}
            color={"text.promoTitle"}
            fontSize={"24px"}
          >
            {t("Member Support")}
          </Typography>
          <Stack spacing={2} mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Box width={"72px"} height={"72px"}>
                  <img className="promotion-icon" src={MessageImage} />
                </Box>
              </Grid>
              <Grid
                item
                xs={8}
                display={"flex"}
                justifyContent={"start"}
                alignItems={"center"}
              >
                <Typography
                  lineHeight={"30.48px"}
                  color={theme?.palette?.text?.secondary}
                  fontSize={"24px"}
                  fontWeight={400}
                >
                  {t("Live Chat")}
                </Typography>
              </Grid>
            </Grid>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Box position={"relative"} width={"72px"} height={"72px"}>
                  <img className="promotion-icon" src={SkypeImage} />
                </Box>
              </Grid>
              <Grid
                item
                xs={8}
                display={"flex"}
                justifyContent={"start"}
                alignItems={"center"}
              >
                <Typography
                  lineHeight={"30.48px"}
                  color={theme?.palette?.text?.secondary}
                  fontSize={"24px"}
                  fontWeight={400}
                >
                  {t("A1BET_CS")}
                </Typography>
              </Grid>
            </Grid>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Box position={"relative"} width={"72px"} height={"72px"}>
                  <img className="promotion-icon" src={MailImage} />
                </Box>
              </Grid>
              <Grid
                item
                xs={8}
                display={"flex"}
                justifyContent={"start"}
                alignItems={"center"}
              >
                <Typography
                  lineHeight={"30.48px"}
                  color={theme?.palette?.text?.secondary}
                  fontSize={"24px"}
                  fontWeight={400}
                >
                  {t("cs@a1bet.cc")}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid> */}
    </Container>
  );
};

export default Promotion;
