import GameList from "@src/views/games";
import SlotGame from "@src/views/games/slot";

// Live Casino
import LiveGame from "@src/views/games/live";

// Lottery
import LotteryGame from "@src/views/games/lottery";
import FishingGame from "@src/views/games/fishing";
import RacingGame from "@src/views/games/racing";

// APP
import AppGame from "@src/views/games/app";
import Promotion from "@src/views/promotion/index";

const GameRoutes = [
  {
    path: "game",
    children: [
      {
        path: "list",
        element: <GameList />,
      },
      {
        path: "slot/:provider",
        element: <SlotGame />,
      },
      {
        path: "fishing/:provider",
        element: <SlotGame />,
      },
    ],
  },
  {
    path: "live-casino",
    children: [
      {
        path: "",
        element: <LiveGame />,
      },
    ],
  },
  {
    path: "fishing",
    children: [
      {
        path: "",
        element: <FishingGame />,
      },
    ],
  },
  {
    path: "racing",
    children: [
      {
        path: "",
        element: <RacingGame />,
      },
    ],
  },
  {
    path: "lottery",
    children: [
      {
        path: "",
        element: <Promotion />,
      },
    ],
  },
  // {
  //   path: "lottery",
  //   children: [
  //     {
  //       path: "",
  //       element: <LotteryGame />,
  //     },
  //   ],
  // },
  {
    path: "app",
    children: [
      {
        path: "",
        element: <AppGame />,
      },
    ],
  },
];

export default GameRoutes;
