import { create } from "zustand";
import PaymentAPI from "@src/apis/payment";

interface BaseStore {
  requestDeposit: any;
  requestWithdraw: any;
  getTransactions: any;
  getTransfers: any;
  requestTransfer: any;
  getTransferList: any;
}

const useStore = create<BaseStore>((set) => ({
  requestDeposit: async (data = {}) => {
    const res = await PaymentAPI.requestDeposit(data);
    if (res) {
      return res.data;
    } else {
      return { status: false, data: false };
    }
  },
  getTransactions: async (data = {}) => {
    const res = await PaymentAPI.getTransactions(data);
    if (res) {
      return res.data;
    } else {
      return { status: false, data: false };
    }
  },
  requestWithdraw: async (data = {}) => {
    const res = await PaymentAPI.requestWithdraw(data);
    if (res) {
      return res.data;
    } else {
      return { status: false, data: false };
    }
  },
  getTransfers: async (data = {}) => {
    const res = await PaymentAPI.getTransfers(data);
    if (res) {
      return res.data;
    } else {
      return { status: false, data: false };
    }
  },
  requestTransfer: async (data = {}) => {
    const res = await PaymentAPI.requestTransfer(data);
    if (res) {
      return res.data;
    } else {
      return { status: false, data: false };
    }
  },
  getTransferList: async (data = {}) => {
    const res = await PaymentAPI.getTransferList(data);
    if (res) {
      return res.data;
    } else {
      return { status: false, data: false };
    }
  },
}));

export default useStore;
