import { Box, Container, Grid, Skeleton } from "@mui/material";
import useBaseStore from "@src/zustand/base";
import useGameStore from "@src/zustand/game";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const SlotGame = () => {
  const navigate = useNavigate();
  const { getGameProviders } = useGameStore((state) => state);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  const goGameList = async (url: string) => {
    navigate(`/game/slot/${url}?type=slots`);
  };

  const init = async () => {
    setLoading(true);
    const res = await getGameProviders({ type: "slot" });
    if (res?.status) {
      setList(res.data);
    } else {
      setList([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Box className="row" my={5}>
        <Container maxWidth="lg">
          <Grid
            container
            display={"flex"}
            spacing={0.5}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {!loading &&
              list.map(
                (item: any, ind: number) =>
                  item && (
                    <Grid key={ind} item md={2} xs={4} sm={4}>
                      <Box
                        onClick={() => goGameList(item.key)}
                        component={"img"}
                        src={`/provider/${item.key}.jpg`}
                        borderRadius={"10px"}
                        sx={{
                          "&:hover": { filter: "blur(5px)" },
                          cursor: "pointer",
                          width: "100%",
                        }}
                      />
                    </Grid>
                  )
              )}

            {loading &&
              [0, 1, 2, 3, 4, 5].map((item: number) => (
                <Skeleton variant="rounded" width={189} height={299} />
              ))}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default SlotGame;
