import { useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Draggable from "react-draggable";
import SupportImg from "@src/assets/images/other/support.png";
import TawkImg from "@src/assets/images/other/tawk.png";
import TelegramImg from "@src/assets/images/other/telegram.png";

const DragChatComponent = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [open, setOpen] = useState<boolean>(false);
  const [isDrag, setIsDrag] = useState(false);

  const handleDialog = (status: boolean) => {
    setOpen(status);
  };

  const goPage = (path: string) => {
    if (path === "telegram") {
      window.open("https://telegram.org", "_blank");
    } else if (path === "tawk") {
      window.open(
        "https://tawk.to/chat/64e77872cc26a871b0312638/1h8k2t039",
        "Support Chat",
        "width=450,height=500"
      );
    }
  };

  const handleDrag = (event: any, info: any) => {
    console.log("event====>", event.type);
    if (event.type === "mousemove" || event.type === "touchmove") {
      setIsDrag(true);
    }
    if (event.type === "mouseup" || event.type === "touchend") {
      console.log("here");
      setTimeout(() => {
        setIsDrag(false);
      }, 100);
    }
  };

  return (
    <>
      <Box
        display="flex"
        flexWrap="nowrap"
        sx={{
          position: "absolute",
          bottom: "10%",
          right: 0,
        }}
      >
        {isMobile ? (
          <IconButton sx={{ padding: 0 }} onClick={() => handleDialog(true)}>
            <Box
              width="80px"
              height="80px"
              className="handle"
              component="img"
              src={SupportImg}
              sx={{
                cursor: "grab",
                "&:active": {
                  cursor: "grabbing",
                },
              }}
            />
          </IconButton>
        ) : (
          <Draggable
            axis="y"
            handle=".handle"
            defaultPosition={{ x: 1, y: 0 }}
            grid={[25, 25]}
            scale={1}
          >
            <IconButton
              className="handle"
              sx={{ padding: 0 }}
              onClick={() => handleDialog(true)}
              onTouchEnd={(e) => {
                handleDialog(true);
              }}
            >
              <Box
                width="80px"
                height="80px"
                className="handle"
                component="img"
                src={SupportImg}
                sx={{
                  cursor: "grab",
                  "&:active": {
                    cursor: "grabbing",
                  },
                }}
              />
            </IconButton>
          </Draggable>
        )}
      </Box>

      <Dialog open={open} onClose={() => handleDialog(false)}>
        <DialogTitle sx={{ bgcolor: "#ffffff" }} fontWeight={800} color="black">
          Support
        </DialogTitle>
        <DialogContent sx={{ bgcolor: "#ffffff" }}>
          <Stack
            direction="row"
            spacing={5}
            justifyContent="center"
            alignItems="center"
          >
            <Box>
              <IconButton onClick={() => goPage("telegram")}>
                <Box
                  component="img"
                  width="80px"
                  height="80px"
                  src={TelegramImg}
                />
              </IconButton>
              <Typography color={theme.palette.grey[500]}>
                Telegram Support
              </Typography>
            </Box>
            <Box>
              <IconButton onClick={() => goPage("tawk")}>
                <Box component="img" width="80px" height="80px" src={TawkImg} />
              </IconButton>
              <Typography color={theme.palette.grey[500]}>Live Chat</Typography>
            </Box>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DragChatComponent;
