import { Box, Grid, Skeleton, Typography, useMediaQuery } from "@mui/material";
import useBaseStore from "@src/zustand/base";
import useGameStore from "@src/zustand/game";
import { Suspense, useEffect, useState } from "react";
import { CoverBox, GameGridItem, GameItem, PlayGameButton } from "./styles";
import Image from "@src/components/image";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { getPerPage } from "@src/configs/game";
import { useParams, useLocation } from "react-router-dom";
import ComingImage from "@src/assets/images/game/comming.png";

const SlotComponent = () => {
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const selectProvider = params?.provider;
  const { setLoading } = useBaseStore((state) => state);
  const { getGames, getGameURL } = useGameStore((state: any) => state);
  const [games, setGames] = useState<any>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [hasMoreGames, setHasMoreGames] = useState<boolean>(true);
  const isMobile = useMediaQuery("(max-width: 768px)");

  const openGame = async (code: any) => {
    setLoading(true);
    const res = await getGameURL({
      provider: selectProvider,
      code,
      isMobile,
      type: searchParams.get("type"),
    });
    if (res?.status)
      window.open(
        res?.data,
        "GameOpen",
        "width=800,height=600,left=100,top=100"
      );
    setLoading(false);
  };

  const handleLoadGames = async (page: number) => {
    setLoading(true);
    const perPage = getPerPage();
    const { status, count, data } = await getGames({
      page,
      perPage,
      provider: selectProvider || "spade",
      type: searchParams.get("type"),
    });
    if (status) {
      const totalCount = count[0]?.count;
      const allGames = page === 0 ? data : [...games, ...data];
      setTotalCount(totalCount);
      setGames(allGames);
      setHasMoreGames(allGames.length < totalCount);
    }
    setLoading(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = document.getElementById("game-body");
      if (
        scrollContainer &&
        scrollContainer?.offsetHeight + scrollContainer?.scrollTop >=
          scrollContainer?.scrollHeight &&
        hasMoreGames
      ) {
        setCurrentPage((prevPage: number) => prevPage + 1);
      }
    };

    const scrollContainer = document.getElementById("game-body");
    scrollContainer?.addEventListener("scroll", handleScroll);

    return () => {
      scrollContainer?.removeEventListener("scroll", handleScroll);
    };
  }, [hasMoreGames]);

  useEffect(() => {
    handleLoadGames(currentPage);
  }, [currentPage]);

  return (
    <>
      <Box
        className="row"
        display={"flex"}
        justifyContent={"center"}
        mt={5}
        mb={5}
      >
        <Box
          className="w-90"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box
            id="game-body"
            sx={{
              width: "100%",
              maxHeight: "100vh",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            <InfiniteScroll
              scrollableTarget="game-body"
              next={() => setCurrentPage(currentPage + 1)}
              hasMore={hasMoreGames}
              loader={<></>}
              dataLength={totalCount}
            >
              <Grid
                container
                display={"flex"}
                spacing={1}
                justifyContent={"center"}
              >
                {games.length > 0 &&
                  games.map((item: any, ind: number) => {
                    // if (item) {
                    return (
                      <Suspense
                        fallback={
                          <GameGridItem item md={2} xs={4} sm={4} key={ind}>
                            <Skeleton variant="rectangular" height={234} />
                          </GameGridItem>
                        }
                      >
                        <GameGridItem item md={2} xs={4} sm={4} key={ind}>
                          <GameItem>
                            <Image
                              image={
                                (item.image &&
                                  item.image.replace(
                                    "a1bet.store",
                                    "ourdemosite.info"
                                  )) ||
                                ComingImage
                              }
                              aspectRatio={4 / 3}
                              placeholder={"GameMainPlaceholder"}
                            />
                            <CoverBox className="cover">
                              <PlayGameButton
                                className="white w-80"
                                size="small"
                                variant="outlined"
                                onClick={() => openGame(item.code)}
                              >
                                <Typography className="white">
                                  {t("Play Now")}
                                </Typography>
                              </PlayGameButton>
                            </CoverBox>
                            <Typography
                              mt={2}
                              textAlign={"center"}
                              fontWeight={700}
                              fontSize={isMobile ? "12px" : "14px"}
                              color={"#244091"}
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              whiteSpace={"nowrap"}
                            >
                              {item.name}
                            </Typography>
                          </GameItem>
                        </GameGridItem>
                      </Suspense>
                    );
                    // } else {
                    //   return (
                    //     <GameGridItem item md={2} xs={4} sm={4} key={ind}>
                    //       <Skeleton variant="rectangular" height={234} />
                    //     </GameGridItem>
                    //   );
                    // }
                  })}
              </Grid>
            </InfiniteScroll>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SlotComponent;
