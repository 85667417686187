import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import useBaseStore from "@src/zustand/base";
import useGameStore from "@src/zustand/game";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Image from "@src/components/image";
import { VolumeUp } from "@mui/icons-material";

const LiveCasino = () => {
  const { setLoading } = useBaseStore((state) => state);
  const { getGameURL, getGameProviders } = useGameStore((state) => state);
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [list, setList] = useState([]);

  const openGame = async (provider: string, code: string) => {
    setLoading(true);
    try {
      const { status, data } = await getGameURL({
        provider: provider,
        code: code,
        type: "live",
        isMobile,
      });
      if (status) {
        window.open(data, "GameOpen", "width=800,height=600,left=100,top=100");
      }
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  };

  const goGameList = (url: string) => {
    if (url.includes("wm")) {
      openGame("wm", "wm");
    } else if (url.includes("og")) {
      openGame("og", "1");
    } else if (url.includes("sexybaccarat")) {
      openGame("sexybaccarat", "sexybaccarat");
    } else if (url.includes("playtech")) {
      openGame("playtech", "ubal");
    } else if (url.includes("sdcdc")) {
      openGame("sdcdc", "sdcdc");
    } else if (url.includes("pragmatic")) {
      openGame("pragmatic", "prag_101");
      // navigate("/game/slot/pragmatic?type=live");
    } else navigate(url);
  };

  const init = async () => {
    const res = await getGameProviders({ type: "live" });
    if (res?.status) {
      setList(res.data);
    } else {
      setList([]);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Box className="row" my={5}>
        <Container maxWidth="lg">
          <Grid
            sx={{ background: theme.palette.background.paper }}
            width={"100%"}
            container
            spacing={2}
            my={2}
            p="5px"
            ml={0}
            border={"1px solid"}
            borderColor={"border.default"}
            borderRadius={"50px"}
            height={"35px"}
          >
            <Grid
              item
              sx={{
                paddingTop: "0 !important",
                paddingLeft: "1% !important",
                cursor: "pointer",
              }}
              xs={12}
              display={"flex"}
              justifyContent={"start"}
              alignItems={"center"}
              onClick={() => goGameList("/announcement")}
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                bgcolor={theme.palette.text.secondary}
                borderRadius={"50px"}
                width={"24px"}
                height={"24px"}
              >
                <VolumeUp
                  sx={{
                    color: theme.palette.background.default,
                    height: "16px",
                  }}
                />
              </Box>
              &nbsp; &nbsp;
              <Typography
                fontWeight={700}
                fontSize={"16px"}
                lineHeight={"15.24px"}
                color={theme.palette.text.secondary}
              >
                {t("Announcement")}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            display={"flex"}
            spacing={3}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {list.map((item: any, ind: number) =>
              item ? (
                <Grid key={ind} item md={2} xs={4} sm={4}>
                  <Box
                    onClick={() => goGameList(`/live-casino/${item.key}`)}
                    component={"img"}
                    src={`/provider/${item.key}.jpg`}
                    borderRadius={"10px"}
                    sx={{
                      "&:hover": { filter: "blur(5px)" },
                      cursor: "pointer",
                      width: "100%",
                    }}
                  />
                </Grid>
              ) : (
                <Skeleton variant="rounded" width={238} height={289} />
              )
            )}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default LiveCasino;
