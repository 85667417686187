import { create } from "zustand";
import AuthAPI from "@src/apis/auth";
import { toast } from "react-toastify";

interface AuthStore {
  isAuth: boolean;
  userData: any;
  addPlayer: any;
  signInUser: any;
  sessionCheck: any;
  userLogout: any;
  handleOTP: any;
  getOTPData: any;
  getCommission: any;
  getRebate: any;
  changePassword: any;
}

const useStore = create<AuthStore>((set) => ({
  isAuth: false,
  userData: {},
  addPlayer: async (data = {}) => {
    const res = await AuthAPI.addPlayer(data);
    return res.data;
  },
  signInUser: async (data = {}) => {
    const res = await AuthAPI.signInUser(data);
    if (res) {
      toast.success(res.data.message);
      set({ isAuth: true, userData: res.data });
      AuthAPI.setToken(res.data.token);
      return true;
    }
  },
  userLogout: async () => {
    await AuthAPI.userLogout();
    AuthAPI.clearToken();
    setTimeout(() => {
      AuthAPI.clearToken();
      window.location.reload();
    }, 100);
  },
  sessionCheck: async () => {
    let token = AuthAPI.getToken();
    if (token) {
      const res = await AuthAPI.sessionCheck();
      set({
        userData: res.data,
        isAuth: true,
      });
    } else {
      AuthAPI.clearToken();
    }
  },
  handleOTP: async (data: string) => {
    const res = await AuthAPI.handleOTP(data);
    return res;
  },
  getOTPData: async (data = {}) => {
    const res = await AuthAPI.getOTPData(data);
    return res;
  },
  getCommission: async (data = {}) => {
    const res = await AuthAPI.getCommission(data);
    return res.data;
  },
  getRebate: async (data = {}) => {
    const res = await AuthAPI.getRebate(data);
    return res.data;
  },
  changePassword: async (data = {}) => {
    const res = await AuthAPI.changePassword(data);
    return res.data;
  },
}));

export default useStore;
