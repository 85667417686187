export const MAIN = {
  token: "ourdemosite",
  // base_url: "http://localhost:3000",
  // server_url: "http://localhost:2003/",
  base_url: "https://ourdemosite.info",
  server_url: "https://ourdemosite.info/",
};

export const CHAT = {
  property_id: "64e77872cc26a871b0312638",
  widget_id: "1h8k2t039",
};
