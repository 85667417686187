import APIAbstract from "./APIAbstract";
import { MAIN } from "../configs";

class SportApi extends APIAbstract {
  getThreeSingGameURL(data: any) {
    return this.post("/v1/sport/getThreeSingGameURL", data);
  }
  getSabaGameURL(data: any) {
    return this.post("/v1/sport/getSabaGameURL", data);
  }
  getRCB988GameURL(data: any) {
    return this.post('/v1/api/rcb988/getGameURL', data);
  }
}

const SportClass = new SportApi(`${MAIN.server_url}`);

export default SportClass;
