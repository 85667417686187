import APIAbstract from "./APIAbstract";
import { MAIN } from "../configs";

class PaymentApi extends APIAbstract {
  requestDeposit(data: any) {
    return this.post("/v1/payment/deposit", data);
  }
  getTransactions(data: any) {
    return this.post("/v1/payment/transactions", data);
  }
  requestWithdraw(data: any) {
    return this.post("/v1/payment/withdraw", data);
  }
  getTransfers(data: any) {
    return this.post("/v1/payment/getTransfer", data);
  }
  requestTransfer(data: any) {
    return this.post("/v1/payment/tansfer", data);
  }
  getTransferList(data: any) {
    return this.post("/v1/payment/tansferList", data);
  }
}

const PaymentClass = new PaymentApi(`${MAIN.server_url}`);

export default PaymentClass;
