import { lazy } from 'react'

const AuthRoutes = [
    {
        path: "auth",
        childrend: [
            {
                path: 'signin',
                element: lazy(() => import('@src/views/auth/signin')),
            },
            {
                path: 'signup',
                element: lazy(() => import('@src/views/auth/signup')),
            },
        ]
    }
];

export default AuthRoutes;