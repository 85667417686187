import {
  CreditCard,
  Directions,
  Search,
  SendRounded,
} from "@mui/icons-material";
import {
  Autocomplete,
  Badge,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  Input,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DataTable from "react-data-table-component";
import useBaseStore from "@src/zustand/base";
import usePaymentStore from "@src/zustand/payment";
import useAuthStore from "@src/zustand/auth";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { CustomPopper, DataTableCustomStyles } from "./style";
import moment from "moment";

interface OptionType {
  label: string;
  value: string;
}

const TransferTab = () => {
  const theme = useTheme();
  const { setLoading } = useBaseStore((state) => state);
  const { userData } = useAuthStore((state) => state);
  const { requestTransfer, getTransferList, getTransfers } = usePaymentStore(
    (state) => state
  );
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [histories, setHistories] = useState([]);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [condition, setCondition] = useState({});
  const [transferOptions, setTransferOptions] = useState([]);
  const [transferData, setTransferData] = useState({
    amount: 0,
    user: "",
  });

  const transferRequest = async () => {
    if (transferData.amount <= 0 || transferData.amount > userData?.cashback) {
      return toast.error("Transfer amount is not valid.");
    }
    setOpen(false);
    setLoading(true);
    setTransferData({
      amount: 0,
      user: "",
    });
    const { status, url } = await requestTransfer(transferData);
    if (status) toast.success("Success");
    getTransferHistory();
    setLoading(false);
  };

  const columns: any = [
    {
      name: t("ID"),
      selector: (row: any) => row._id,
    },
    {
      name: t("Sender"),
      selector: (row: any) => <Typography>{row.send}</Typography>,
    },
    {
      name: t("To"),
      selector: (row: any) => <Typography>{row.to}</Typography>,
    },
    {
      name: t("Amount"),
      selector: (row: any) => (
        <Typography>{Number(row.amount).toFixed(2)}</Typography>
      ),
    },
    {
      name: t("Time"),
      selector: (row: any) => (
        <span>{moment(row.createdAt).format("YYYY-MM-DD hh:mm:ss")}</span>
      ),
    },
  ];

  const getTransferHistory = async () => {
    setLoading(true);
    const { status, data } = await getTransferList({ condition });
    if (status) setHistories(data);
    setLoading(false);
  };

  const getTransferUsers = async () => {
    setLoading(true);
    const { status, data } = await getTransfers();
    if (status) {
      const options: any = [];
      data.map((item: any) => {
        options.push({ label: item.mobile, value: item.id });
      });
      setTransferOptions(options);
    }
    setLoading(false);
  };

  useEffect(() => {
    getTransferHistory();
  }, []);

  useEffect(() => {
    if (open) getTransferUsers();
  }, [open]);

  return (
    <Box className="row">
      <Card sx={{ borderRadius: "8px" }}>
        <CardContent>
          <Box
            className="row"
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box sx={{ border: "1px solid", borderRadius: "4px" }}>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search Mobile"
                inputProps={{ "aria-label": "search mobile" }}
                onChange={(e) =>
                  setCondition({ ...condition, mobile: e.target.value })
                }
              />
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
                onClick={getTransferHistory}
              >
                <Search />
              </IconButton>
            </Box>

            <Button
              // size="small"
              variant="outlined"
              color="primary"
              startIcon={<CreditCard />}
              onClick={() => setOpen(true)}
            >
              {t("Transfer")}
            </Button>
          </Box>
          <Box className="row">
            <DataTable
              noHeader
              subHeader
              pagination
              responsive
              theme="solarized"
              columns={columns}
              data={histories}
              customStyles={DataTableCustomStyles}
            />
          </Box>
        </CardContent>
      </Card>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        sx={{
          "& .MuiDialog-container .MuiPaper-root": {
            backgroundColor: "background.default",
          },
        }}
      >
        <DialogTitle id="responsive-dialog-title" color={"text.default"}>
          {t("Transfer")}
        </DialogTitle>
        <DialogContent>
          <Box
            gap={4}
            margin={1}
            display="flex"
            flexDirection={{ md: "row", sm: "column", xs: "column" }}
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <FormControl fullWidth>
              <Autocomplete
                fullWidth
                options={transferOptions}
                renderInput={(params) => <TextField {...params} label="To" />}
                size="small"
                sx={{
                  width: "100%",
                }}
                PopperComponent={CustomPopper}
                onChange={(event: React.SyntheticEvent, newValue: any) =>
                  setTransferData({ ...transferData, user: newValue.value })
                }
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                label="Amount"
                type="number"
                fullWidth
                onChange={(e: any) =>
                  setTransferData({ ...transferData, amount: e.target.value })
                }
                value={transferData.amount}
                size="small"
                inputProps={{ min: 0 }}
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={transferRequest}
            startIcon={<SendRounded />}
            fullWidth
            autoFocus
          >
            {t("Transfer")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TransferTab;
