import {
  Box,
  Container,
  Grid,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import useBaseStore from "@src/zustand/base";
import useGameStore from "@src/zustand/game";
import useAuthStore from "@src/zustand/auth";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Image from "@src/components/image";

const FishingGame = () => {
  const { loading, setLoading } = useBaseStore((state) => state);
  const { getYLGameURL, getGameProviders } = useGameStore((state) => state);
  const { userData } = useAuthStore((state) => state);
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [list, setList] = useState([]);

  const openGame = async (type: string) => {
    setLoading(true);
    navigate(`/game/${type}?type=fishing`);
    setLoading(false);
  };

  const init = async () => {
    setLoading(true);
    const res = await getGameProviders({ type: "fishing" });
    if (res?.status) {
      setList(res.data);
    } else {
      setList([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Box className="row" my={5}>
        <Container maxWidth="lg">
          <Grid
            container
            display={"flex"}
            spacing={3}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {list.map((item: any, ind: number) =>
              item ? (
                <Grid key={ind} item md={2} xs={4} sm={4}>
                  <Box
                    onClick={() => openGame(`fishing/${item?.key}`)}
                    component={"img"}
                    src={`/provider/${item.key}.jpg`}
                    borderRadius={"10px"}
                    sx={{
                      "&:hover": { filter: "blur(5px)" },
                      cursor: "pointer",
                      width: "100%",
                    }}
                  />
                </Grid>
              ) : (
                <Skeleton variant="rounded" width={238} height={289} />
              )
            )}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default FishingGame;
