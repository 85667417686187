import {
  AccountCircle,
  CloseOutlined,
  Lock,
  PhoneAndroid,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

// Logo
import A1BetLogo from "@src/assets/logo.png";
import A1BetMobileLogo from "@src/assets/mobile_logo.png";
import SignInImage from "@src/assets/images/auth/signin.png";
import { toast } from "react-toastify";

import useAuthStore from "@src/zustand/auth";

const initialSiginData = {
  mobile: "",
  password: "",
};

const SignIn = ({
  fullScreen,
  signInOpen = false,
  handleSignInDialog,
  handleSignUpDialog,
  handleMouseDownPassword,
}: any) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { signInUser } = useAuthStore((state) => state);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [signInData, setSignInData] = useState(initialSiginData);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleSignInData = (key: string, value: string) => {
    setSignInData({ ...signInData, [key]: value });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!signInData.mobile || !signInData.password) {
      toast.error("Please Input All Fields.");
    } else {
      const res = await signInUser(signInData);
      if (res) {
        handleSignInDialog(false);
        setSignInData(initialSiginData);
      }
    }
  };

  if (isMobile)
    return (
      <>
        <Dialog
          fullScreen={fullScreen}
          open={signInOpen}
          onClose={handleSignInDialog}
          aria-labelledby="responsive-dialog-title"
          sx={{
            "& .MuiDialogContent-root ": {
              backgroundColor: theme.palette.background.default,
            },
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => handleSignInDialog(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseOutlined />
          </IconButton>
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                height={"85vh"}
              >
                <Box
                  gap={"8px"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box>
                    <Box
                      mb={2}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <img src={A1BetLogo} />
                    </Box>
                    <Box
                      mb={2}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">
                          Mobile
                        </InputLabel>
                        <OutlinedInput
                          startAdornment={
                            <InputAdornment position="start">
                              <PhoneAndroid />
                            </InputAdornment>
                          }
                          id="outlined-adornment-username"
                          type="text"
                          label="Mobile"
                          value={signInData?.mobile}
                          onChange={(e) =>
                            handleSignInData("mobile", e.target.value)
                          }
                        />
                      </FormControl>
                    </Box>
                    <Box
                      mb={2}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">
                          Password
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={showPassword ? "text" : "password"}
                          startAdornment={
                            <InputAdornment position="start">
                              <Lock />
                            </InputAdornment>
                          }
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                          value={signInData?.password}
                          onChange={(e) =>
                            handleSignInData("password", e.target.value)
                          }
                        />
                      </FormControl>
                    </Box>
                    <Box
                      ml={1}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      flexDirection="column"
                      gap={2}
                    >
                      <Button
                        variant="outlined"
                        fullWidth
                        color="primary"
                        type="submit"
                      >
                        {t("Sign In")}
                      </Button>
                      <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        type="button"
                        onClick={() => {
                          handleSignInDialog(false);
                          handleSignUpDialog(true);
                        }}
                      >
                        {t("Sign Up")}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </form>
          </DialogContent>
        </Dialog>
      </>
    );

  return (
    <Dialog
      fullScreen={fullScreen}
      open={signInOpen}
      onClose={handleSignInDialog}
      aria-labelledby="responsive-dialog-title"
      sx={{
        "& .MuiPaper-root": {
          backgroundColor: theme.palette.background.default,
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={() => handleSignInDialog(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseOutlined />
      </IconButton>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Box display={"flex"}>
            <Box>
              <img src={SignInImage} />
            </Box>
            <Box
              gap={"8px"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box>
                <Box
                  mb={2}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <img src={A1BetLogo} />
                </Box>
                <Box
                  mb={2}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Mobile
                    </InputLabel>
                    <OutlinedInput
                      startAdornment={
                        <InputAdornment position="start">
                          <PhoneAndroid />
                        </InputAdornment>
                      }
                      id="outlined-adornment-username"
                      type="text"
                      label="Mobile"
                      value={signInData?.mobile}
                      onChange={(e) =>
                        handleSignInData("mobile", e.target.value)
                      }
                    />
                  </FormControl>
                </Box>
                <Box
                  mb={2}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      startAdornment={
                        <InputAdornment position="start">
                          <Lock />
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                      value={signInData?.password}
                      onChange={(e) =>
                        handleSignInData("password", e.target.value)
                      }
                    />
                  </FormControl>
                </Box>
                <Box
                  m={1}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Button
                    variant="outlined"
                    fullWidth
                    color="primary"
                    type="submit"
                  >
                    {t("Sign In")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default SignIn;
