import { Link, Outlet } from "react-router-dom";

// Layout
import Layout from "@src/layout/layout";

// Error
import ErrorComponent from "@src/components/error";

// Routes
import AuthRoutes from "./auth";
import SportRoutes from "./sport";
import GameRoutes from "./games";
import BaseRoutes from "./base";

const AppRoutes: any = [
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorComponent />,
    children: [...AuthRoutes, ...SportRoutes, ...GameRoutes, ...BaseRoutes],
  },
];

export default AppRoutes;
