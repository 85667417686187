import APIAbstract from "./APIAbstract";
import { MAIN } from "../configs";

class GameApi extends APIAbstract {
  getGameProviders(data: any) {
    return this.post("/v1/game/getGameProviders", data);
  }
  getGames(data: any) {
    return this.post("/v1/game/getGames", data);
  }
  getGameURL(data: any) {
    return this.post("/v1/game/getGameURL", data);
  }

  getPragmaticGames(data: any) {
    return this.post("/v1/game/getPragmaticGames", data);
  }
  getPragmaticGameURL(data: any) {
    return this.post("/v1/api/pragmactic/getGameURL", data);
  }

  // BT Gaming
  getBTGames(data: any) {
    return this.post("/v1/game/getBTGames", data);
  }
  getBTGameURL(data: any) {
    return this.post("/v1/api/bt/getGameURL", data);
  }

  // Habanero Gaming
  getHabaneroGames(data: any) {
    return this.post("/v1/game/getHabaneroGames", data);
  }
  getHabaneroGameURL(data: any) {
    return this.post("/v1/api/habanero/getGameURL", data);
  }

  // CQ9 Gaming
  getCQ9Games(data: any) {
    return this.post("/v1/game/getCQ9Games", data);
  }
  getCQ9GameURL(data: any) {
    return this.post("/v1/api/cq9/getGameURL", data);
  }
  // Playtech
  getPlaytechGames(data: any) {
    return this.post("/v1/game/getPlaytechGames", data);
  }
  getPlaytechGameURL(data: any) {
    return this.post("/v1/api/playtech/getGameURL", data);
  }
  // FC
  getFCGames(data: any) {
    return this.post("/v1/game/getFCGames", data);
  }
  getFCGameURL(data: any) {
    return this.post("/v1/api/fc/getGameURL", data);
  }
  // WM
  getWMGameURL(data: any) {
    return this.post("/v1/api/wm/getGameURL", data);
  }
  // VBOSS
  getVBOSSGameURL(data: any) {
    return this.post("/v1/api/vboss/getGameURL", data);
  }
  withdrawVBOSS(data: any) {
    return this.post("/v1/api/vboss/withdraw", data);
  }

  // Playstar
  getPlaystarGames(data: any) {
    return this.post("/v1/game/getPlaystarGames", data);
  }

  // OG
  getOGGames(data: any) {
    return this.post("/v1/game/getOGGames", data);
  }
  getOGGameURL(data: any) {
    return this.post("/v1/api/og/getGameURL", data);
  }
  // JDB
  getJDBGames(data: any) {
    return this.post("/v1/game/getJDBGames", data);
  }
  getJDBGameURL(data: any) {
    return this.post("/v1/api/jdb/getGameURL", data);
  }
  getSV388GameURL(data: any) {
    return this.post("/v1/api/sv388/getGameURL", data);
  }
  getSexyBaccaratGameURL(data: any) {
    return this.post("/v1/api/sexybaccarat/getGameURL", data);
  }
  getCobaGameURL(data: any) {
    return this.post("/v1/api/coba/login", data);
  }
  getCobaProfile(data: any) {
    return this.post("/v1/api/coba/getProfile", data);
  }
  withdrawCoba(data: any) {
    return this.post("/v1/api/coba/withdraw", data);
  }
  depositCoba(data: any) {
    return this.post("/v1/api/coba/deposit", data);
  }
  getEvolutionGameURL(data: any) {
    return this.post("/v1/api/evolution/getGameURL", data);
  }
  getYLGameURL(data: any) {
    return this.post("/v1/api/yl/getGameURL", data);
  }
  // APP
  getMegaGameURL(data: any) {
    return this.post("/v1/api/mega/getGameURL", data);
  }
  getMegaBalance(data: any) {
    return this.post("/v1/api/mega/balance", data);
  }
  depositMega(data: any) {
    return this.post("/v1/api/mega/transfer", data);
  }
  withdrawMega(data: any) {
    return this.post("/v1/api/mega/transferout", data);
  }
  loginMega(data: any) {
    return this.post("/v1/api/mega/login", data);
  }
  setMegaPass(data: any) {
    return this.post("/v1/api/mega/token", data);
  }
}

const AuthClass = new GameApi(`${MAIN.server_url}`);

export default AuthClass;
