export const BankList = [
  { label: "Alliance Bank Malaysia Berhad", value: "MFBBMYKL" },
  { label: "Ambank (M) Berhad", value: "ARBKMYKL" },
  { label: "Bank Islam Malaysia Berhad", value: "BIMBMYKL" },
  { label: "Bank Simpasan Nasional (BSN)", value: "BSNAMYK1" },
  { label: "Cimb Bank Berhad", value: "CIBBMYKL" },
  { label: "Hong Leong Bank Berhad", value: "HLBBMYKL" },
  { label: "Malayan Banking Berhad (Maybank)", value: "MBBEMYKL" },
  { label: "Public Bank Berhad", value: "PBBEMYKL" },
  { label: "RHB Bank Berhad", value: "RHBBMYKL" },
];
