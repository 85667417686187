import { Box, Container } from "@mui/material";
import HeaderComponent from "./header";
import { Outlet } from "react-router-dom";
import FooterCompoent from "./footer";
import ChatComponent from "@src/components/chat";
import PageTracking from "@src/components/track";
import DragChatComponent from "@src/components/chat/drag";

const Layout = () => {
  return (
    <Container
      sx={{ height: "100%", overflowX: "hidden" }}
      maxWidth={false}
      disableGutters={true}
    >
      <HeaderComponent />
      <Box sx={{ backgroundColor: "background.default" }}>
        <Outlet />
      </Box>
      <DragChatComponent />
      <PageTracking />
      <FooterCompoent />
    </Container>
  );
};

export default Layout;
