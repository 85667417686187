import { create } from "zustand";
import ProfileAPI from "@src/apis/profile";
import { toast } from "react-toastify";

interface ProfileStore {
  getGameHistory: any;
}

const useStore = create<ProfileStore>((set) => ({
  getGameHistory: async (data = {}) => {
    const res = await ProfileAPI.getGameHistory(data);
    if (res) {
      return res.data;
    } else {
      return {};
    }
  },
}));

export default useStore;
