import { Box, Button, Grid } from "@mui/material";
import styled from "styled-components";

export const GameGridItem = styled(Grid)``;

// background: linear-gradient(0deg, #ffffff, #ffffff),
//   linear-gradient(
//     180deg,
//     #f7fbff 0%,
//     #ffffff 28.65%,
//     #e7eefe 68.23%,
//     #ffffff 100%
//   );
export const GameItem = styled(Box)`
  border: 1.86px solid #ffffff;
  border-radius: 13.93px;
  color: #fff;
  padding: 5px;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 13.93px;
  box-shadow: 0px 2px 1px -1px rgba (0, 0, 0, 0.2),
    0px 1px 1px 0px rgba (0, 0, 0, 0.14), 0px 1px 3px 0px rgba (0, 0, 0, 0.12);
  overflow: hidden;
  height: 100%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    transition: 0.75s;
    border-radius: 10px;
  }
  &:hover {
    .cover {
      background: rgba(0, 0, 0, 0.5);
      width: 100%;
      border-radius: 0;
      top: 0;
      margin-left: -5px;
    }
    img {
      transform: rotate(10deg) scale(1.5);
      border-radius: 10px;
    }
  }
`;

export const CoverBox = styled(Box)`
  width: 100%;
  height: 100%;
  margin-left: -15px;
  transition: all 0.3s ease-in 0s !important;
  position: absolute;
  border-radius: 50%;
  top: 100%;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const PlayGameButton = styled(Button)`
  background-color: rgb(36, 64, 145) !important;
  color: white;
  border-color: unset;
  border-width: 0;
  &:hover {
    border-color: unset;
    border-width: 0;
  }
`;

export const AppBox = styled(Box)`
  --borderS: 2rem;
  aspect-ratio: 1;
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  align-item: center;
  position: relative;
  &::before,
  &::after {
    inset: 0;
    position: absolute;
    border-radius: 50%;
    border: var(--borderS) solid white;
    line-height: 1.1;
  }
  &::after {
    content: "";
    filter: drop-shadow(-0.25rem 0.25rem 0.0675rem rgba(0, 0, 0, 0.75))
      blur(5px);
  }
`;
