import { Box } from "@mui/material";
import Promotion from "@src/components/dashboard/promotion";
import Feature from "@src/components/dashboard/feature";

const Dashboard = () => {
  return (
    <>
      <Box className="row w-full">
        <Promotion />
      </Box>
      {/* <Box className="row">
        <Feature />
      </Box> */}
    </>
  );
};

export default Dashboard;
