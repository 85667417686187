// Browser List
import GoogleProvider from "@src/assets/images/footer/browser/google.png";
import MozilaProvder from "@src/assets/images/footer/browser/mozila.png";
import SafariProvider from "@src/assets/images/footer/browser/safari.png";
import CronProvider from "@src/assets/images/footer/browser/cron.png";
import EdgeProvider from "@src/assets/images/footer/browser/edge.png";
// Browser List End

export const FooterMenus = [
  { title: "About Us", link: "" },
  { title: "Responsible Gaming", link: "" },
  { title: "Info Centre", link: "" },
  { title: "Contact Us", link: "" },
  { title: "Affiliates", link: "" },
  // { title: "Blog", link: "" },
  // { title: "Rewards", link: "" },
  // { title: "Download Centre", link: "" },
  // { title: "Refer a Friend", link: "" },
];

export const FooterDetails = [
  [
    { title: "INFORMATION", description: "" },
    {
      title: "Registration",
      description:
        "Open an Demosite account to enjoy all our online betting promotions and gaming entertainment of premium quality at exceptionally good value. We believe in rewarding our valued customers with different type of deposit bonuses and promotions.",
    },
    {
      title: "Affiliates",
      description:
        "Become our partner and earn high commission levels every month by driving players to Demosite.",
    },
    {
      title: "Responsible Gaming",
      description:
        "Demosite strives to provide a channel of entertainment to our customer in a positive way. We have in place major safeguards to promote and ensure responsible gambling.",
    },
    {
      title: "Security",
      description:
        "A Solid and Secure Betting System. The privacy of your into is important to us and we adhere to strict confidentiality and privacy policies.",
    },
  ],
  [
    { title: "PRODUCTS", description: "" },
    {
      title: "Sportsbook Betting",
      description:
        "We offer all the major sports such as English Premier League, Spanish La Liga, Italian Serie A, UEFA Champions League, French Ligue 1, German Bundesliga 1, NFL, NBA, NCAA, Women Basketball, Tennis, Formula 1 and many more. We offer up to 4,000 Live Betting soccer matches a month for your wagering pleasure.",
    },
    {
      title: "Online Casino",
      description:
        "Play casino games online. Choose from Roulette, Blackjack, Video Poker, Slots, Progressives and others plus get your chance to win more Casino Jackpots on Demosite.",
    },
    {
      title: "Live Casino",
      description:
        "We have extensive range of offers. These include Live Dealer Casino with Live Lobby view, beautiful Asian dealers, Live Baccarat, Live Sic Bo, Live Dragon Tiger and Live Roulette, Online Casino Slots and easy to play Keno games.",
    },
  ],
  [
    { title: "INFO CENTRE", description: "" },
    {
      title: "Promotions",
      description:
        "Deposit Bonus and Welcome Bonus offer for all new members. Reload Bonus and Cash Rebates for existing customers as well.",
    },
    {
      title: "Help Centre",
      description:
        "Check out our FAQ page for sports betting and gaming help. It contains help on account opening, deposit, withdrawal and technical help.",
    },
    {
      title: "Payment Methods",
      description:
        "We provide great choice for customers to deposit and withdraw when you win via NETELLER, Moneybookers, International Bank Transfer, Western Union and many more. We have it all, great games start with Demosite..",
    },
    {
      title: "Contact Us",
      description:
        "If you need any help or have any questions about Live betting or our casino games, we are available 24/7 via Live Chat, Telephone and E-mail.",
    },
  ],
  [
    { title: "BETTING INFO", description: "" },
    {
      title: "Sports Results",
      description: "Get all of the results from sports and matches we offer.",
    },
    {
      title: "Betting Statistics",
      description:
        "Over 200 tournaments across all major sports showing league tables, fixtures, form, team and player statistics, injury and suspension lists.",
    },
    {
      title: "Sports Betting",
      description:
        "Rules Our sports rules section details the rules and regulations under which bets are accepted and provides information on sports betting rules.",
    },
    {
      title: "Casino Betting",
      description:
        "Our Live Casino provides into such as Limit, Live Pool, Result History and Replay Hand History. All necessary into is displayed on screen for your betting convenience. You can choose hide or show different into at anytime.",
    },
  ],
];

export const BrowserProviderList = [
  GoogleProvider,
  MozilaProvder,
  SafariProvider,
  EdgeProvider,
];
