import { lazy } from "react";

import ReferFriend from "@src/views/other/reference";
import Announcement from "@src/views/other/announcement";
import Profile from "@src/views/profile/index";
import Promotion from "@src/views/promotion";

const BaseRoutes = [
  {
    path: "promotion",
    element: <Promotion />,
  },
  {
    path: "profile",
    element: <Profile />,
  },
  {
    path: "reference",
    element: <ReferFriend />,
  },
  {
    path: "announcement",
    element: <Announcement />,
  },
];

export default BaseRoutes;
