import { CreditCard } from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Input,
  MenuItem,
  Select,
  Stack,
  Table,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DataTable from "react-data-table-component";
import useBaseStore from "@src/zustand/base";
import usePaymentStore from "@src/zustand/payment";
import useAuthStore from "@src/zustand/auth";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { DataTableCustomStyles } from "./style";
import moment from "moment";

const DepositTab = () => {
  const theme = useTheme();
  const { setLoading } = useBaseStore((state) => state);
  const { userData } = useAuthStore((state) => state);
  const { requestDeposit, getTransactions } = usePaymentStore((state) => state);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [histories, setHistories] = useState([]);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [amount, setAmount] = useState(0);
  const [bankCode, setBankCode] = useState("qr");

  const deposit = async () => {
    if (amount < 30 || amount > 200000) {
      return toast.error("Deposit Amount must be from 30 to 200000.");
    }
    if (amount > userData.balance) {
      return toast.error("Balance is not enough.");
    }
    if (!bankCode) {
      return toast.error("Please select the bank code");
    }

    setOpen(false);
    setLoading(true);
    setAmount(0);
    const { status, url } = await requestDeposit({ amount, code: bankCode });
    if (status)
      window.open(url, "GameOpen", "width=800,height=600,left=100,top=100");
    getDepositHistory();
    setLoading(false);
  };

  const columns: any = [
    {
      name: t("ID"),
      selector: (row: any) => row._id,
    },
    {
      name: t("Time"),
      selector: (row: any) => (
        <span>{moment(row.createdAt).format("YYYY-MM-DD hh:mm:ss")}</span>
      ),
    },
    {
      name: t("Account"),
      selector: (row: any) => <Typography>{row.account}</Typography>,
    },
    {
      name: t("Amount"),
      selector: (row: any) => (
        <Typography>{Number(row.amount).toFixed(2)}</Typography>
      ),
    },
    {
      name: t("Status"),
      selector: (row: any) => (
        <Typography
          color={row.status === "success" ? "success" : "grey"}
          textTransform={"uppercase"}
        >
          {row.status}
        </Typography>
      ),
    },
  ];

  const getDepositHistory = async () => {
    setLoading(true);
    const { status, data } = await getTransactions({ type: "deposit" });
    if (status) setHistories(data);
    setLoading(false);
  };

  useEffect(() => {
    getDepositHistory();
  }, []);

  return (
    <Box className="row">
      <Card sx={{ borderRadius: "8px" }}>
        <CardContent>
          <Box
            className="row"
            display={"flex"}
            justifyContent={"end"}
            alignItems={"center"}
          >
            <Button
              // size="small"
              variant="outlined"
              color="primary"
              startIcon={<CreditCard />}
              onClick={() => setOpen(true)}
            >
              {t("Deposit")}
            </Button>
          </Box>
          <Box className="row">
            <DataTable
              noHeader
              subHeader
              pagination
              responsive
              theme="solarized"
              columns={columns}
              data={histories}
              customStyles={DataTableCustomStyles}
            />
          </Box>
        </CardContent>
      </Card>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        sx={{
          "& .MuiDialog-container .MuiPaper-root": {
            backgroundColor: "background.default",
          },
        }}
      >
        <DialogTitle id="responsive-dialog-title" color={"text.default"}>
          {t("Deposit")}
        </DialogTitle>
        <DialogContent>
          <Table>
            <TableRow>
              <TableCell color={"text.default"}>{t("Min")}</TableCell>
              <TableCell color={"text.default"}>30</TableCell>
            </TableRow>
            <TableRow>
              <TableCell color={"text.default"}>{t("Max")}</TableCell>
              <TableCell color={"text.default"}>200000</TableCell>
            </TableRow>
            <TableRow>
              <TableCell color={"text.default"}>{t("Option")}</TableCell>
              <TableCell color={"text.default"}>
                <Select
                  fullWidth
                  size="small"
                  value={bankCode}
                  onChange={(e) => setBankCode(e.target.value)}
                >
                  <MenuItem value={"qr"}>QR Payment</MenuItem>
                  <MenuItem value={"p2p"}>P2P Payment</MenuItem>
                </Select>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell color={"text.default"}>{t("Amount")}</TableCell>
              <TableCell>
                <Input
                  type="number"
                  fullWidth
                  onChange={(e: any) => setAmount(e.target.value)}
                  value={amount}
                />
              </TableCell>
            </TableRow>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={deposit}
            startIcon={<CreditCard />}
            fullWidth
            autoFocus
          >
            {t("Deposit")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DepositTab;
