import { create } from "zustand";
import GameAPI from "@src/apis/game";

interface GameStore {
  getGameProviders: any;
  getGames: any;
  getGameURL: any;
  getPragmaticGames: any;
  getPragmaticGameURL: any;
  getEvolutionGameURL: any;
  getWMGameURL: any;
  getOGGameURL: any;
  getPlaytechGameURL: any;
  getVBOSSGameURL: any;
  withdrawVBOSS: any;
  getSV388GameURL: any;
  getSexyBaccaratGameURL: any;
  getCobaGameURL: any;
  getCobaProfile: any;
  depositCoba: any;
  withdrawCoba: any;
  getYLGameURL: any;
  getMegaGameURL: any;
  getMegaBalance: any;
  depositMega: any;
  withdrawMega: any;
  loginMega: any;
  setMegaPass: any;
}

const useStore = create<GameStore>((set: any) => ({
  getGameProviders: async (data = {}) => {
    const res = await GameAPI.getGameProviders(data);
    return res.data;
  },
  getGames: async (data = {}) => {
    const res = await GameAPI.getGames(data);
    return res.data;
  },
  getGameURL: async (data = {}) => {
    const res = await GameAPI.getGameURL(data);
    return res.data;
  },
  getPragmaticGames: async (data = {}) => {
    const res = await GameAPI.getPragmaticGames(data);
    return res;
  },
  getPragmaticGameURL: async (data = "") => {
    const res = await GameAPI.getPragmaticGameURL(data);
    return res;
  },
  // BT Gaming
  getBTGames: async (data = {}) => {
    const res = await GameAPI.getBTGames(data);
    return res;
  },
  getBTGameURL: async (data = "") => {
    const res = await GameAPI.getBTGameURL(data);
    return res;
  },
  // Habanero Gaming
  getHabaneroGames: async (data = {}) => {
    const res = await GameAPI.getHabaneroGames(data);
    return res;
  },
  getHabaneroGameURL: async (data = "") => {
    const res = await GameAPI.getHabaneroGameURL(data);
    return res;
  },
  // CQ9 Gaming
  getCQ9Games: async (data = {}) => {
    const res = await GameAPI.getCQ9Games(data);
    return res;
  },
  getCQ9GameURL: async (data = "") => {
    const res = await GameAPI.getCQ9GameURL(data);
    return res;
  },
  // Playtech
  getPlaytechGames: async (data = {}) => {
    const res = await GameAPI.getPlaytechGames(data);
    return res;
  },
  getPlaytechGameURL: async (data = {}) => {
    const res = await GameAPI.getPlaytechGameURL(data);
    return res?.data;
  },
  // FC
  getFCGames: async (data = {}) => {
    const res = await GameAPI.getFCGames(data);
    return res;
  },
  getFCGameURL: async (data = "") => {
    const res = await GameAPI.getFCGameURL(data);
    return res;
  },

  // WM
  getWMGameURL: async (data = "") => {
    const res = await GameAPI.getWMGameURL(data);
    return res;
  },

  // VBOSS
  getVBOSSGameURL: async (data = "") => {
    const res = await GameAPI.getVBOSSGameURL(data);
    return res;
  },
  withdrawVBOSS: async (data = "") => {
    const res = await GameAPI.withdrawVBOSS(data);
    return res;
  },

  // Playstar
  getPlaystarGames: async (data = {}) => {
    const res = await GameAPI.getPlaystarGames(data);
    return res;
  },
  // OG
  getOGGames: async (data = {}) => {
    const res = await GameAPI.getOGGames(data);
    return res;
  },
  getOGGameURL: async (data = "") => {
    const res = await GameAPI.getOGGameURL(data);
    return res;
  },
  // JDB
  getJDBGames: async (data = {}) => {
    const res = await GameAPI.getJDBGames(data);
    return res;
  },
  getJDBGameURL: async (data = {}) => {
    const res = await GameAPI.getJDBGameURL(data);
    return res;
  },
  // SV388
  getSV388GameURL: async (data = {}) => {
    const res = await GameAPI.getSV388GameURL(data);
    return res.data;
  },

  // Sexy Baccarat
  getSexyBaccaratGameURL: async (data = {}) => {
    const res = await GameAPI.getSexyBaccaratGameURL(data);
    return res.data;
  },

  // Coba
  getCobaGameURL: async (data = {}) => {
    const res = await GameAPI.getCobaGameURL(data);
    return res.data;
  },
  getCobaProfile: async (data = {}) => {
    const res = await GameAPI.getCobaProfile(data);
    return res.data;
  },
  depositCoba: async (data = {}) => {
    const res = await GameAPI.depositCoba(data);
    return res.data;
  },
  withdrawCoba: async (data = {}) => {
    const res = await GameAPI.withdrawCoba(data);
    return res.data;
  },
  getEvolutionGameURL: async (data = {}) => {
    const res = await GameAPI.getEvolutionGameURL(data);
    return res.data;
  },
  getYLGameURL: async (data = {}) => {
    const res = await GameAPI.getYLGameURL(data);
    return res.data;
  },
  // APP
  getMegaGameURL: async (data = {}) => {
    const res = await GameAPI.getMegaGameURL(data);
    return res.data;
  },
  getMegaBalance: async (data = {}) => {
    const res = await GameAPI.getMegaBalance(data);
    return res.data;
  },
  depositMega: async (data = {}) => {
    const res = await GameAPI.depositMega(data);
    return res.data;
  },
  withdrawMega: async (data = {}) => {
    const res = await GameAPI.withdrawMega(data);
    return res.data;
  },
  loginMega: async (data = {}) => {
    const res = await GameAPI.loginMega(data);
    return res.data;
  },
  setMegaPass: async (data = {}) => {
    const res = await GameAPI.setMegaPass(data);
    return res.data;
  },
}));

export default useStore;
