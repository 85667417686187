import LiveCasino from "@src/views/games/live";
import Dashboard from "@src/views/sport/dashboard";
import SportList from "@src/views/sport/list";

const SportRoutes = [
  {
    path: "/",
    element: <Dashboard />,
  },
  // {
  //   path: "/",
  //   element: <LiveCasino />,
  // },
  {
    path: "/sport",
    children: [
      {
        path: "list",
        element: <SportList />,
      },
    ],
  },
];

export default SportRoutes;
