import {
  AddCard,
  ContentCopy,
  CreditCard,
  Email,
  Lock,
  Person,
  PhoneAndroid,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import useAuthStore from "@src/zustand/auth";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const ProfileTab = ({ setTabIndex }: any) => {
  const { t } = useTranslation();
  const { userData, changePassword } = useAuthStore((state) => state);
  const [pass, setPass] = useState({ current: "", new: "", confirm: "" });
  const [showPassword, setShowPassword]: any = useState({
    current: false,
    new: false,
    confirm: false,
  });

  const handleChangePass = (type: string, value: string) => {
    setPass({ ...pass, [type]: value });
  };

  const handleClickShowPassword = (target: string) =>
    setShowPassword({ ...showPassword, [target]: !showPassword[target] });

  const handleSubmitPass = async () => {
    if (!pass.current || !pass.new || !pass.confirm) {
      return toast.error("Please Input All Field.");
    }
    if (pass.new !== pass.confirm) {
      return toast.error("Password didn't match.");
    }
    const sendData = {
      id: userData._id,
      current_pass: pass.current,
      new_pass: pass.new,
    };
    const res = await changePassword(sendData);
    if (res) toast.success(res);
    setPass({ current: "", new: "", confirm: "" });
  };

  const copyText = async (text: string) => {
    await window.navigator.clipboard.writeText(text);
  };

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item md={3} sm={12} xs={12}>
        <Card
          sx={{
            py: 3,
            px: 2,
            borderRadius: "16px",
            bgcolor: "background.default",
          }}
        >
          <CardContent sx={{ pt: 8 }}>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box
                component={"img"}
                src="https://w7.pngwing.com/pngs/753/432/png-transparent-user-profile-2018-in-sight-user-conference-expo-business-default-business-angle-service-people-thumbnail.png"
                sx={{
                  borderRadius: "50%",
                  width: "100px",
                }}
              />
            </Box>
            <Typography
              variant="h5"
              textAlign={"center"}
              mt={2}
              color={"text.default"}
            >
              {userData?.mobile}
            </Typography>
          </CardContent>
          <Stack
            mt={2}
            direction={"row"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            bgcolor={"background.footer"}
            mx={1}
            my={2}
            paddingX={"15px"}
            paddingY={"10px"}
            borderRadius={"8px"}
          >
            <Stack
              direction={"row"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={"8px"}
            >
              <CreditCard sx={{ color: "text.default" }} />
              <Typography color={"text.default"} component={"h5"}>
                {t("Balance")}
              </Typography>
            </Stack>
            <Stack direction={"row"} gap={2}>
              <Typography color={"text.default"} component={"h4"}>
                {Number(userData?.balance).toFixed(2)}
              </Typography>
              <Typography color={"text.default"} component={"h5"}>
                {t("MYR")}
              </Typography>
            </Stack>
          </Stack>
          <CardActions>
            <Button
              fullWidth
              size="small"
              color="success"
              variant="contained"
              className="white"
              startIcon={<AddCard />}
              onClick={() => setTabIndex(1)}
            >
              {t("Deposit")}
            </Button>
          </CardActions>
          <CardActions>
            <Button
              fullWidth
              size="small"
              color="info"
              variant="contained"
              className="white"
              startIcon={<CreditCard />}
              onClick={() => setTabIndex(2)}
            >
              {t("Withdraw")}
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item md={5} sm={12} xs={12}>
        <Card sx={{ py: 3, px: 2, borderRadius: "16px" }}>
          <CardContent>
            <Typography
              fontWeight={800}
              variant="h5"
              component="div"
              color={"text.default"}
            >
              {t("Profile")}
            </Typography>
            <Box mt={2}>
              <TextField
                fullWidth
                id="outlined-start-adornment"
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => copyText(userData?._id)}>
                        <ContentCopy />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={userData?._id}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Username"
                id="outlined-start-adornment"
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Person />
                    </InputAdornment>
                  ),
                }}
                sx={{ mb: 2 }}
                value={userData?.name}
              />
              <TextField
                fullWidth
                label="Email"
                id="outlined-start-adornment"
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email />
                    </InputAdornment>
                  ),
                }}
                sx={{ mb: 2 }}
                value={userData?.name}
              />
              <TextField
                fullWidth
                label="Mobile"
                id="outlined-start-adornment"
                disabled
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneAndroid />
                    </InputAdornment>
                  ),
                }}
                sx={{ mb: 2 }}
                value={userData?.mobile}
              />
            </Box>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              color="success"
              variant="contained"
              className="white"
              fullWidth
              sx={{ my: 2 }}
            >
              {t("Save")}
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item md={4} sm={12} xs={12}>
        <Card sx={{ py: 3, px: 2, borderRadius: "16px" }}>
          <CardContent>
            <Typography
              fontWeight={800}
              variant="h5"
              component="div"
              color={"text.default"}
            >
              {t("Change Password")}
            </Typography>
            <Box mt={2}>
              <FormControl sx={{ mb: 2, width: "100%" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  {t("Current Password")}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  fullWidth
                  type={showPassword.current ? "text" : "password"}
                  value={pass.current}
                  onChange={(e) => handleChangePass("current", e.target.value)}
                  startAdornment={
                    <InputAdornment position="start">
                      <Lock />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleClickShowPassword("current")}
                        edge="end"
                      >
                        {showPassword.current ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
              <FormControl sx={{ mb: 2, width: "100%" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  {t("New Password")}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword?.new ? "text" : "password"}
                  value={pass.new}
                  onChange={(e) => handleChangePass("new", e.target.value)}
                  startAdornment={
                    <InputAdornment position="start">
                      <Lock />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleClickShowPassword("new")}
                        edge="end"
                      >
                        {showPassword?.new ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
              <FormControl sx={{ width: "100%" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  {t("Confirm Password")}
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword?.confirm ? "text" : "password"}
                  value={pass.confirm}
                  onChange={(e) => handleChangePass("confirm", e.target.value)}
                  startAdornment={
                    <InputAdornment position="start">
                      <Lock />
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleClickShowPassword("confirm")}
                        edge="end"
                      >
                        {showPassword?.confirm ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
            </Box>
          </CardContent>
          <CardActions>
            <Button
              size="small"
              color="success"
              variant="contained"
              className="white"
              fullWidth
              sx={{ my: 2 }}
              onClick={handleSubmitPass}
            >
              {t("Change Password")}
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ProfileTab;
