import APIAbstract from "./APIAbstract";
import { MAIN } from "../configs";

class ProfileApi extends APIAbstract {
  getGameHistory(data: any) {
    return this.post("/v1/profile/getGameHisotry", data);
  }
}

const ProfileClass = new ProfileApi(`${MAIN.server_url}`);

export default ProfileClass;
