import {
  Button,
  Tab,
  TableCell,
  TableRow,
  Tabs,
  tableCellClasses,
} from "@mui/material";
import styled from "styled-components";

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

interface StyledTabProps {
  label: string;
}

export const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    variant="scrollable"
    scrollButtons="auto"
    allowScrollButtonsMobile
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  borderRadius: "16px",
  backgroundColor: "#5ec6f1 !important",
  "& .MuiTabs-flexContainer": {
    display: "flex",
  },
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
});

export const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  color: "white !important",
  borderRadius: "16px",
  "&.Mui-selected": {
    borderRadius: "16px",
    backgroundColor: "#244091 !important",
    color: "#fff",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#5ec6f1 !important",
  color: "white !important",
  borderColor: "white !important",
  "&:hover": {
    backgroundColor: "#337ab7 !important",
  },
}));
