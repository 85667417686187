import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAuthStore from "@src/zustand/auth";

const AuthProvider = ({ children }: any) => {
  const { i18n } = useTranslation();
  const { sessionCheck } = useAuthStore((state) => state);
  const [flag, setFlag] = useState(false);

  const init = async () => {
    sessionCheck();
    setFlag(true);
  };
  useEffect(() => {
    init();
    // eslint-disable-next-line
  }, []);

  return <>{flag ? children : ""}</>;
};

export default AuthProvider;
