import { AccountBalance, BlockSharp, CreditCard } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import DataTable from "react-data-table-component";
import { useTheme } from "@mui/material/styles";
import useBaseStore from "@src/zustand/base";
import SpeedPayImage from "@src/assets/images/payment/speedpay.png";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import usePaymentStore from "@src/zustand/payment";
import { toast } from "react-toastify";
import { DataTableCustomStyles } from "./style";
import { BankList } from "@src/configs/payment";
import moment from "moment";

interface BankProps {
  name: string;
  code: string;
  order: string;
  amount: number;
}

const WithdrawTab = () => {
  const theme = useTheme();
  const { setLoading } = useBaseStore((state) => state);
  const { requestWithdraw, getTransactions } = usePaymentStore(
    (state) => state
  );
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [histories, setHistories] = useState([]);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [bank, setBank] = useState<BankProps>({
    name: "",
    code: "",
    order: "",
    amount: 0,
  });

  const withdraw = async () => {
    if (bank.amount < 30 || bank.amount > 20000) {
      return toast.error("Withdraw Amount must be from 30 to 20000.");
    }
    if (!bank.name || !bank.code || !bank.order || !bank.amount) {
      return toast.error("Please input all fields.");
    }
    setOpen(false);
    setBank({
      name: "",
      code: "",
      order: "",
      amount: 0,
    });
    setLoading(true);
    const res = await requestWithdraw({
      accountName: bank.name,
      accountCode: bank.code,
      accountNo: bank.order,
      amount: bank.amount,
    });
    if (res.status) toast.success(res.data);
    getWithdrawHistory();
    setLoading(false);
  };

  const columns: any = [
    {
      name: t("ID"),
      selector: (row: any) => row._id,
    },
    {
      name: t("Time"),
      selector: (row: any) => (
        <span>{moment(row.createdAt).format("YYYY-MM-DD hh:mm:ss")}</span>
      ),
    },
    {
      name: t("Account"),
      selector: (row: any) => <Typography>{row.account}</Typography>,
    },
    {
      name: t("Amount"),
      selector: (row: any) => (
        <Typography>{Number(row.amount).toFixed(2)}</Typography>
      ),
    },
    {
      name: t("Status"),
      selector: (row: any) => (
        <Typography
          color={row.status === "success" ? "success" : "grey"}
          textTransform={"uppercase"}
        >
          {row.status}
        </Typography>
      ),
    },
  ];

  const getWithdrawHistory = async () => {
    setLoading(true);
    const { status, data } = await getTransactions({ type: "withdraw" });
    if (status) setHistories(data);
    setLoading(false);
  };

  useEffect(() => {
    getWithdrawHistory();
  }, []);

  return (
    <Box className="row">
      <Card sx={{ borderRadius: "8px" }}>
        <CardContent>
          <Box
            className="row"
            display={"flex"}
            justifyContent={"end"}
            alignItems={"center"}
          >
            <Button
              // size="small"
              variant="outlined"
              color="primary"
              startIcon={<CreditCard />}
              onClick={() => setOpen(true)}
            >
              {t("Withdraw")}
            </Button>
          </Box>
          <Box className="row">
            <DataTable
              noHeader
              subHeader
              pagination
              responsive
              theme="solarized"
              columns={columns}
              data={histories}
              customStyles={DataTableCustomStyles}
            />
          </Box>
        </CardContent>
      </Card>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        sx={{
          "& .MuiDialog-container .MuiPaper-root": {
            backgroundColor: "background.default",
          },
        }}
      >
        <DialogContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box width="100%">
            <Typography fontWeight={800} fontSize="20px">
              Withdraw
            </Typography>
            <br />
            <Box className="row" display={"flex"} gap={2}>
              <FormControl
                size="small"
                sx={{ maxWidth: "238px", width: "238px" }}
              >
                <InputLabel id="bank-code">Min</InputLabel>
                <OutlinedInput
                  label="Min"
                  size="small"
                  startAdornment={
                    <InputAdornment position="start">
                      <BlockSharp />
                    </InputAdornment>
                  }
                  value={30}
                  disabled
                />
              </FormControl>
              <FormControl
                size="small"
                sx={{ maxWidth: "238px", width: "238px" }}
              >
                <InputLabel id="bank-code">Max</InputLabel>
                <OutlinedInput
                  label="Max"
                  size="small"
                  startAdornment={
                    <InputAdornment position="start">
                      <BlockSharp />
                    </InputAdornment>
                  }
                  value={20000}
                  disabled
                />
              </FormControl>
            </Box>
            <br />
            <Box className="row" display={"flex"} gap={2}>
              <OutlinedInput
                size="small"
                startAdornment={
                  <InputAdornment position="start">
                    <AccountBalance />
                  </InputAdornment>
                }
                placeholder="Acc Name"
                onChange={(e) => setBank({ ...bank, name: e.target.value })}
                value={bank.name}
              />
              <OutlinedInput
                size="small"
                startAdornment={
                  <InputAdornment position="start">
                    <AccountBalance />
                  </InputAdornment>
                }
                placeholder="Acc No"
                onChange={(e) => setBank({ ...bank, order: e.target.value })}
                value={bank.order}
              />
            </Box>
            <br />
            <Box className="row" display={"flex"} gap={2}>
              <FormControl
                size="small"
                sx={{ maxWidth: "238px", width: "238px" }}
              >
                <InputLabel id="bank-code">Acc Code</InputLabel>
                <Select
                  labelId="bank-code"
                  // size="small"
                  value={bank.code}
                  onChange={(e: any) =>
                    setBank({ ...bank, code: e?.target?.value })
                  }
                >
                  {BankList.map((item) => (
                    <MenuItem value={item.value}>{item.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <OutlinedInput
                type="number"
                size="small"
                startAdornment={
                  <InputAdornment position="start">
                    <AccountBalance />
                  </InputAdornment>
                }
                placeholder="Withdraw Amount"
                onChange={(e) =>
                  setBank({ ...bank, amount: Number(e.target.value) })
                }
                value={bank.amount}
              />
            </Box>
            <br />
            <Button
              className="white"
              variant="contained"
              color="success"
              onClick={withdraw}
              startIcon={<CreditCard />}
              fullWidth
              autoFocus
            >
              {t("Withdraw")}
            </Button>
          </Box>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </Box>
  );
};

export default WithdrawTab;
