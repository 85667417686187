// Image
import NovaImage from "@src/assets/images/feature/nova.png";
import MSportImage from "@src/assets/images/feature/m_sport.png";
import AsiaImage from "@src/assets/images/feature/asia.png";
import MobileNovaImage from "@src/assets/images/feature/mobile_nova.png";
import MobileMSportImage from "@src/assets/images/feature/mobile_m_sport.png";
import MobileAsiaImage from "@src/assets/images/feature/mobile_asia.png";
import AllBetImage from "@src/assets/images/feature/allbet.png";
import Cq9Image from "@src/assets/images/feature/cq9.png";
import LegImage from "@src/assets/images/feature/leg.png";
import {
  Casino,
  Home,
  PersonAddOutlined,
  PhoneAndroid,
  SportsEsports,
  SportsSoccer,
} from "@mui/icons-material";

// SVG Icons
import EGameIcon from "@src/assets/images/header/e-game.svg";
import LotteryIcon from "@src/assets/images/header/lottery.svg";
import FishingIcon from "@src/assets/images/header/fishing.svg";
import RacingIcon from "@src/assets/images/header/racing.svg";

export const MobileMenuList = [
  // { title: "home", icon: <Home />, link: "/" },
  { title: "sportsbook", icon: <SportsSoccer />, link: "/sport/list" },
  { title: "livecasino", icon: <Casino />, link: "/live-casino" },
  {
    title: "e-games",
    icon: <img width="20px" src={EGameIcon} />,
    link: "/game/list",
  },
  {
    title: "fishing",
    icon: <img width="20px" src={FishingIcon} />,
    link: "/fishing",
  },
  {
    title: "racing & fighting",
    icon: <img width="20px" src={RacingIcon} />,
    link: "/racing",
  },
  // {
  //   title: "lottery",
  //   icon: <img width="20px" src={LotteryIcon} />,
  //   link: "/lottery",
  // },
  // { title: "App", icon: <PhoneAndroid />, link: "/app" },
  { title: "refer friend", icon: <PersonAddOutlined />, link: "/reference" },
];

export const SubMenu = [
  { title: "HOME", value: "home", link: "" },
  { title: "SPORTS", value: "sports", link: "sport/list" },
  { title: "LIVE CASINO", value: "live_casino", link: "live-casino" },
  { title: "E-GAMES", value: "slots", link: "game/list" },
  { title: "Fishing", value: "fishing", link: "fishing" },
  { title: "Racing & Fighting", value: "racing", link: "racing" },
  // { title: "LOTTERY", value: "lottery", link: "lottery" },
  // { title: "App", value: "app", link: "app" },
  { title: "Promotion", value: "promotion", link: "promotion" },
];

export const SliderSettings = {
  autoPlay: true,
  showArrows: false,
  showStatus: false,
};

export const FeatureList = [
  { title: "nova sports", img: NovaImage },
  { title: "m-sports", img: MSportImage },
  { title: "Asia gaming", img: AsiaImage },
  { title: "Allbet", img: AllBetImage },
  { title: "CQ9", img: Cq9Image },
  { title: "LEG", img: LegImage },
];

export const MoboleFeatureList = [
  { title: "nova sports", img: MobileNovaImage },
  { title: "m-sports", img: MobileMSportImage },
  { title: "Asia gaming", img: MobileAsiaImage },
];
