import { Box, Popper, Tab, Tabs } from "@mui/material";
import { createTheme } from "react-data-table-component";
import styled from "styled-components";

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

interface StyledTabProps {
  label: string;
  icon: any;
  iconPosition: any;
  color: any;
}

interface StyledTabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    variant="scrollable"
    scrollButtons="auto"
    allowScrollButtonsMobile
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  borderRadius: "16px",
  marginBottom: "15px",
  "& .MuiTabs-flexContainer": {
    display: "flex",
  },
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
});

export const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  minHeight: "45px !important",
  height: "45px !important",
  // color: "text.secondary !important",
  textTransform: "none",
  borderRadius: "16px",
  "&.Mui-selected": {
    borderRadius: "16px",
    backgroundColor: "#244091 !important",
    color: "#fff !important",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

export const StyledTabPanel = (props: StyledTabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export const DataTableTheme = createTheme("solarized", {
  background: {
    default: "none",
  },
  context: {
    text: "#FFFFFF",
  },
  text: {
    primary: "text.default",
    secondary: "text.default",
  },
});

export const DataTableCustomStyles = {
  headCells: {
    style: {
      backgroundColor: "background.default",
      fontSize: "1rem",
      fontWeight: 800,
    },
  },
};

export const CustomPopper = styled(Popper)(({ theme }) => ({
  background: "white",
  fontSize: "25px",
}));
