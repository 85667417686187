import { useState } from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  FooterMenus,
  FooterDetails,
  BrowserProviderList,
} from "@src/configs/footer";
import { useTranslation } from "react-i18next";

// Logo
import A1BetLogo from "@src/assets/logo.png";
import A1BetMobileLogo from "@src/assets/mobile_logo.png";
import PaymentLogos from "@src/assets/images/footer/payment/payment.jpg";
import ProviderMobileLogos from "@src/assets/images/footer/provider/provider-mobile.png";
import ProviderLogos from "@src/assets/images/footer/provider/provider-desktop.png";
import {
  AddCard,
  CreditCardOff,
  Home,
  Person,
  PersonSearch,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const FooterCompoent = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [mobileMenu, setMobileMenu] = useState(0);
  const navigator = useNavigate();

  if (isMobile) {
    return (
      <>
        <Box>
          <Box
            bgcolor={"#244091"}
            height={"25px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Stack
              direction="row"
              divider={
                <Divider
                  sx={{ backgroundColor: "#FFFFFF" }}
                  orientation="vertical"
                  flexItem
                />
              }
              spacing={2}
              display={"flex"}
              justifyContent={"center"}
              py={2}
              px={1}
            >
              {FooterMenus.map((item, ind) => (
                <Typography
                  key={ind}
                  fontSize={"7px"}
                  fontWeight={400}
                  lineHeight={"8.89px"}
                  color={"#FFFFFF"}
                  textAlign={"center"}
                >
                  {t(item.title)}
                </Typography>
              ))}
            </Stack>
          </Box>
          <Box
            bgcolor={"background.footer"}
            pt={5}
            display={"flex"}
            justifyContent={"center"}
          >
            <Grid
              className="w-80"
              container
              spacing={2}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Grid item xs={12}>
                <Typography
                  textAlign={"center"}
                  fontWeight={700}
                  fontSize={"12px"}
                  color={"#A8A8A8"}
                  lineHeight={"14.32px"}
                >
                  {t("Provider")}
                </Typography>
                <Stack
                  display={"flex"}
                  justifyContent={"center"}
                  flexWrap={"wrap"}
                  direction="row"
                  spacing={2}
                >
                  <Box
                    component="img"
                    width="125%"
                    height="auto"
                    src={ProviderMobileLogos}
                  />
                </Stack>
              </Grid>
              {/* <Grid item xs={12}>
                <Typography
                  fontWeight={700}
                  textAlign={"center"}
                  fontSize={"12px"}
                  color={"#A8A8A8"}
                  lineHeight={"14.32px"}
                >
                  {t("Payment Method")}
                </Typography>
                <Stack
                  display={"flex"}
                  justifyContent={"center"}
                  flexWrap={"wrap"}
                  direction="row"
                >
                  <Box component="img" src={PaymentLogos} />
                </Stack> */}
              {/* <Typography
                  mt={2}
                  fontWeight={700}
                  fontSize={"12px"}
                  color={"#A8A8A8"}
                  lineHeight={"14.32px"}
                  textAlign={"center"}
                >
                  {t("Browser")}
                </Typography>
                <Stack
                  mt={4}
                  display={"flex"}
                  justifyContent={"center"}
                  flexWrap={"wrap"}
                  direction="row"
                  spacing={2}
                >
                  {BrowserProviderList.map((item, ind) => (
                    <Box key={ind}>
                      <img src={item} />
                    </Box>
                  ))}
                </Stack> */}
              {/* </Grid> */}
            </Grid>
          </Box>
          <Box bgcolor={""} height={"30px"}>
            {/* <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              mt={3}
            >
              <img src={A1BetMobileLogo} />
            </Box> */}
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              mt={1}
            >
              <Typography
                color={"text.default"}
                fontWeight={400}
                fontSize={"16px"}
                lineHeight={"20.32px"}
                textAlign={"center"}
              >
                {t("©2024 DEMOSITE. All rights reserved | 18+")}
              </Typography>
            </Box>
          </Box>
          <Box bgcolor={""} height={"65px"} />
        </Box>
        <Paper
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: theme.palette.background.default,
            zIndex: 100,
          }}
          elevation={3}
        >
          <BottomNavigation
            showLabels
            value={mobileMenu}
            onChange={(event, newValue) => {
              setMobileMenu(newValue);
            }}
            sx={{ minHeight: "60px" }}
          >
            <BottomNavigationAction
              sx={{ minWidth: "50px !important" }}
              href="/"
              label="Home"
              icon={
                <Home sx={{ fontSize: "30px", width: "90%", height: "auto" }} />
              }
            />
            <BottomNavigationAction
              sx={{ minWidth: "50px !important" }}
              label="Deposit"
              onClick={() => navigator("/profile?page=deposit")}
              icon={
                <AddCard
                  sx={{ fontSize: "30px", width: "90%", height: "auto" }}
                />
              }
            />
            <BottomNavigationAction
              sx={{ minWidth: "50px !important" }}
              href="/reference"
              label="Referral"
              icon={
                <PersonSearch
                  sx={{ fontSize: "30px", width: "90%", height: "auto" }}
                />
              }
            />
            <BottomNavigationAction
              sx={{ minWidth: "50px !important" }}
              label="Withdraw"
              onClick={() => navigator("/profile?page=withdraw")}
              icon={
                <CreditCardOff
                  sx={{ fontSize: "30px", width: "90%", height: "auto" }}
                />
              }
            />
            <BottomNavigationAction
              sx={{ minWidth: "50px !important" }}
              onClick={() => navigator("/profile")}
              label="Account"
              icon={
                <Person
                  sx={{ fontSize: "30px", width: "90%", height: "auto" }}
                />
              }
            />
          </BottomNavigation>
        </Paper>
      </>
    );
  }

  return (
    <>
      <Box>
        <Box bgcolor={"#244091"} height={"48px"}>
          <Stack
            direction="row"
            divider={
              <Divider
                sx={{ backgroundColor: "#FFFFFF" }}
                orientation="vertical"
                flexItem
              />
            }
            spacing={2}
            display={"flex"}
            justifyContent={"center"}
            pt={2}
            pb={2}
          >
            {FooterMenus.map((item, ind) => (
              <Typography
                key={ind}
                fontSize={"12px"}
                fontWeight={400}
                lineHeight={"15.24px"}
                color={"#FFFFFF"}
                textAlign={"center"}
              >
                {t(item.title)}
              </Typography>
            ))}
          </Stack>
        </Box>
        {/* <Box
          bgcolor={"background.footer"}
          display={"flex"}
          justifyContent={"center"}
        >
          <Grid
            container
            spacing={2}
            display={"flex"}
            justifyContent={"center"}
            className="w-80"
            pt={2}
            pb={2}
          >
            {FooterDetails.map((item: any, ind: number) => {
              return (
                <Grid item xs={3} key={ind}>
                  {item.map((cItem: any, cInd: number) => (
                    <Box mt={2} key={`${ind}-${cInd}`}>
                      <Typography
                        color={"#A8A8A8"}
                        fontSize={"16px"}
                        fontWeight={700}
                        lineHeight={"19.09px"}
                      >
                        {t(cItem.title)}
                      </Typography>
                      <Typography
                        color={"#A8A8A8"}
                        fontSize={"12px"}
                        fontWeight={400}
                        lineHeight={"15.24px"}
                      >
                        {t(cItem.description)}
                      </Typography>
                    </Box>
                  ))}
                </Grid>
              );
            })}
          </Grid>
        </Box> */}
        <Box
          bgcolor={"background.footer"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box className="w-80">
            <Divider sx={{ borderStyle: "dotted" }} variant="middle" />
          </Box>
        </Box>
        <Box
          bgcolor={"background.footer"}
          pt={5}
          display={"flex"}
          justifyContent={"center"}
        >
          <Grid
            className="w-80"
            container
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Grid item xs={12}>
              <Typography
                fontWeight={700}
                fontSize={"12px"}
                color={"#A8A8A8"}
                lineHeight={"14.32px"}
              >
                {t("Provider")}
              </Typography>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  component="img"
                  width="100%"
                  height="auto"
                  src={ProviderLogos}
                />
              </Stack>
            </Grid>
            {/* <Grid item xs={4}>
              <Typography
                fontWeight={700}
                fontSize={"12px"}
                color={"#A8A8A8"}
                lineHeight={"14.32px"}
              >
                {t("Payment Method")}
              </Typography>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Box component="img" src={PaymentLogos} /> */}
            {/* {PaymentProviderList.map((item, ind) => (
                  <Box key={ind}>
                    <img width="50px" height="50px" src={item} />
                  </Box>
                ))} */}
            {/* </Stack> */}
            {/* <Typography
                mt={2}
                fontWeight={700}
                fontSize={"12px"}
                color={"#A8A8A8"}
                lineHeight={"14.32px"}
              >
                {t("Browser")}
              </Typography>
              <Stack
                mt={4}
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                {BrowserProviderList.map((item, ind) => (
                  <Box key={ind}>
                    <img src={item} />
                  </Box>
                ))}
              </Stack> */}
            {/* </Grid> */}
          </Grid>
        </Box>
        <Box bgcolor={""} height={"35px"}>
          {/* <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            mt={3}
          >
            <img className="logo" src={A1BetLogo} />
          </Box> */}
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            mt={1}
          >
            <Typography
              color={"text.default"}
              fontWeight={400}
              fontSize={"16px"}
              lineHeight={"20.32px"}
              textAlign={"center"}
            >
              {t("©2024 DEMOSITE. All rights reserved | 18+")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default FooterCompoent;
