import { useEffect, useState } from "react";
import moment from "moment";
import { Card, CardContent } from "@mui/material";
import DataTable from "react-data-table-component";
import useProfileStore from "@src/zustand/profile";
import useAuthStore from "@src/zustand/auth";
import useBaseStore from "@src/zustand/base";
import { useTranslation } from "react-i18next";
import { DataTableCustomStyles } from "./style";

const GameHistoryTab = () => {
  const { setLoading } = useBaseStore((state) => state);
  const { t } = useTranslation();
  const { userData } = useAuthStore((state) => state);
  const { getGameHistory } = useProfileStore((state) => state);
  const [data, setData] = useState([]);

  const init = async () => {
    setLoading(true);
    const { status, data } = await getGameHistory({ id: userData?.id });
    if (status) {
      setData(data);
    } else {
      setData([]);
    }
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  const columns = [
    {
      name: t("ID"),
      selector: (row: any, ind: any) => row.id,
    },
    {
      name: t("Provider"),
      selector: (row: any) => row.provider,
    },
    {
      name: t("Bet"),
      selector: (row: any) => Math.abs(row.bet),
    },
    {
      name: t("Win"),
      selector: (row: any) => Math.abs(row.win),
    },
    {
      name: t("Amount"),
      selector: (row: any) => row.amount,
    },
    {
      name: t("Time"),
      selector: (row: any) =>
        moment(Number(row.time)).format("YYYY-MM-DD HH:mm:ss"),
    },
  ];

  return (
    <Card>
      <CardContent>
        <DataTable
          noHeader
          subHeader
          pagination
          responsive
          theme="solarized"
          columns={columns}
          data={data}
          customStyles={DataTableCustomStyles}
        />
      </CardContent>
    </Card>
  );
};

export default GameHistoryTab;
