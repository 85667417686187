import {
  Alert,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Collapse,
  Container,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Skeleton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import useBaseStore from "@src/zustand/base";
import useGameStore from "@src/zustand/game";
import useSportSotre from "@src/zustand/sport";
import useAuthStore from "@src/zustand/auth";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Image from "@src/components/image";
import { AppBox } from "./styles";
import {
  CloseOutlined,
  ExpandLess,
  ExpandMore,
  Lock,
  Payment,
  PlayCircleOutline,
} from "@mui/icons-material";
import { toast } from "react-toastify";

const AppGame = () => {
  const { setLoading } = useBaseStore((state) => state);
  const {
    getGameProviders,
    getGameURL,
    getMegaBalance,
    depositMega,
    getMegaGameURL,
    withdrawMega,
    setMegaPass,
  } = useGameStore((state) => state);
  const { userData, sessionCheck } = useAuthStore((state) => state);
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [password, setPassword] = useState<string>("");
  const [amount, setAmount] = useState<number>(0);
  const [openDt, setOpenDt] = useState<{ status: boolean; title: string }>({
    status: false,
    title: "",
  });
  const [list, setList] = useState([]);
  const [profileID, setProfileID] = useState<string>("");
  const [profileToken, setProfileToken] = useState<boolean>(false);
  const [profileAmount, setProfileAmount] = useState<Number>(0);
  const [expand, setExpand] = useState<string>(
    userData?.token?.mega ? "deposit" : "password"
  );
  const setToken = async () => {
    if (!password && !profileToken) {
      return toast.error("Please set your password!");
    }
    setOpenDt({ status: false, title: "mega" });
    setLoading(true);
    const res = await setMegaPass({ id: profileID, password });
    if (res?.status) {
      setExpand("deposit");
      setOpenDt({ status: true, title: "mega" });
    }
    setLoading(false);
  };

  const openGame = async (provider: string) => {
    setLoading(true);
    setOpenDt({ title: "mega", status: false });
    if (provider === "mega") {
      const res = await getMegaGameURL({
        id: profileID,
        password,
        status: profileToken,
      });
      if (res?.status)
        window.open(
          res?.data,
          "GameOpen",
          "width=800,height=600,left=100,top=100"
        );
    }
    setLoading(false);
  };

  const withdraw = async () => {
    handleDialogOpen(false);
    setLoading(true);
    const res = await withdrawMega({
      amount: profileAmount,
    });
    if (res?.status) {
      toast.success("Success.");
    }
    setAmount(0);
    await sessionCheck();
    setLoading(false);
  };

  const deposit = async () => {
    handleDialogOpen(false);
    setLoading(true);
    if (!amount) return toast.error("Please input deposit amount!");
    if (amount > 0 && amount <= userData?.balance) {
      const res = await depositMega({ amount });
      if (res?.status) {
        toast.success("Success.");
      }
    } else {
      toast.error(`Deposit max amount is ${userData?.balance}`);
    }
    setAmount(0);
    await sessionCheck();
    setLoading(false);
  };

  const handleDialogOpen = (status: boolean) => {
    setOpenDt({ ...openDt, status });
  };

  const goGameList = async (url: string) => {
    setLoading(true);
    if (url.includes("mega")) {
      const res = await getGameURL({
        provider: "mega",
        code: "mega",
        type: "app",
        isMobile,
      });
      if (res?.status)
        window.open(
          res?.data,
          "GameOpen",
          "width=800,height=600,left=100,top=100"
        );
    }
    setLoading(false);
    return false;
  };

  const getBalance = async (status: boolean, provider: string) => {
    if (status) {
      setLoading(true);
      if (provider === "mega") {
        const res = await getMegaBalance();
        if (res?.status) {
          setProfileID(res?.login);
          setProfileAmount(res?.data);
          setProfileToken(res?.token);
          setOpenDt({ status, title: provider });
        }
      }
      setLoading(false);
    }
  };

  const init = async () => {
    const res = await getGameProviders({ type: "app" });
    if (res?.status) {
      setList(res.data);
    } else {
      setList([]);
    }
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    setExpand(userData?.token?.mega ? "deposit" : "password");
  }, [userData?.token?.mega]);

  return (
    <>
      <Box className="row" my={5}>
        <Container maxWidth="lg">
          <Grid
            container
            display={"flex"}
            spacing={3}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {list.map((item: any, ind: number) =>
              item ? (
                <Grid key={ind} item md={2} xs={4} sm={4}>
                  <Box
                    className="shine-icon"
                    onClick={() => getBalance(true, item.key)}
                    component={"img"}
                    src={`/provider/${item.key}.jpg`}
                    borderRadius={"10px"}
                    sx={{
                      "&:hover": { filter: "blur(5px)" },
                      cursor: "pointer",
                      width: "100%",
                    }}
                  />
                </Grid>
              ) : (
                <Skeleton variant="rounded" width={238} height={289} />
              )
            )}
          </Grid>
        </Container>
      </Box>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        open={openDt?.status}
        onClose={() => handleDialogOpen(false)}
        aria-labelledby="responsive-dialog-title"
        sx={{
          "& .MuiDialogContent-root ": {
            backgroundColor: theme.palette.background.default,
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => handleDialogOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseOutlined />
        </IconButton>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box width="100%">
            <Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  gap={2}
                  alignItems="center"
                >
                  <Typography fontSize={16}>Login ID :</Typography>
                  <Typography
                    color="text.secondary"
                    fontSize={16}
                    fontWeight={800}
                  >
                    {profileID}
                  </Typography>
                </Box>
              </Box>
              {(expand === "deposit" || expand === "withdraw") && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gap={2}
                >
                  <Typography fontSize="14px" fontWeight={800}>
                    {`${openDt.title.toUpperCase()} Game Balance`} :{" "}
                  </Typography>
                  <TextField
                    id="balance"
                    size="small"
                    sx={{ maxWidth: "100px" }}
                    defaultValue={profileAmount || 0}
                    disabled
                  />
                </Box>
              )}
            </Box>
            <List
              sx={{ width: "100%", bgcolor: "background.secondary" }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              {(expand === "deposit" || expand === "withdraw") && (
                <>
                  <ListItemButton onClick={() => setExpand("deposit")}>
                    <ListItemText primary="Amount" />
                    {expand === "deposit" || expand === "withdraw" ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItemButton>
                  <Collapse
                    in={expand === "deposit" || expand === "withdraw"}
                    timeout="auto"
                    unmountOnExit
                  >
                    <TextField
                      type="number"
                      fullWidth
                      value={amount}
                      onChange={(e: any) => setAmount(e.target.value)}
                      size="small"
                      InputProps={{
                        inputProps: { min: 0, max: userData?.balance },
                      }}
                      disabled={userData?.balance > 0 ? false : true}
                    />
                  </Collapse>
                </>
              )}
              {expand === "password" && (
                <>
                  <ListItemButton onClick={() => setExpand("password")}>
                    <ListItemText primary="Set Password" />
                    {expand === "password" ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  <Collapse
                    in={expand === "password"}
                    timeout="auto"
                    unmountOnExit
                  >
                    <TextField
                      type="password"
                      fullWidth
                      value={password}
                      onChange={(e: any) => setPassword(e.target.value)}
                      size="small"
                      placeholder="Password"
                    />
                  </Collapse>
                </>
              )}
            </List>
            {openDt?.title === "mega" && (
              <>
                <Divider variant="middle" />
                <Alert severity="info">
                  Please use above Login ID to login into your account.
                </Alert>
                <Divider variant="middle" />
              </>
            )}
            <Box
              display="flex"
              flexDirection={isMobile ? "column" : "row"}
              gap={1}
              mt={2}
            >
              {expand !== "password" && (
                <>
                  <Button
                    variant="contained"
                    color="warning"
                    fullWidth
                    size="small"
                    startIcon={<Payment />}
                    onClick={withdraw}
                    disabled={Number(profileAmount) > 0 ? false : true}
                  >
                    Withdraw All
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    size="small"
                    color="success"
                    onClick={() => openGame(openDt.title)}
                    startIcon={<PlayCircleOutline />}
                  >
                    Open Game
                  </Button>
                  <Button
                    variant="contained"
                    color="info"
                    fullWidth
                    size="small"
                    startIcon={<Payment />}
                    onClick={deposit}
                    disabled={userData?.balance > 0 ? false : true}
                  >
                    Deposit
                  </Button>
                </>
              )}
              {expand === "password" && (
                <Button
                  variant="contained"
                  color="info"
                  fullWidth
                  size="small"
                  startIcon={<Lock />}
                  onClick={setToken}
                >
                  Set Password
                </Button>
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AppGame;
