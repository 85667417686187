import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useBaseStore from "@src/zustand/base";
import useAuthStore from "@src/zustand/auth";
import {
  Box,
  Toolbar,
  FormControlLabel,
  Typography,
  useMediaQuery,
  Button,
  IconButton,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Divider,
  Tooltip,
  Menu,
  MenuItem,
  Stack,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useTranslation } from "react-i18next";

// Icons
import {
  PersonAddOutlined,
  AccountCircle,
  Person2Outlined,
  ExitToAppOutlined,
  WalletRounded,
  AddCard,
  Refresh,
  Login,
  Logout,
  PersonAdd,
  LogoutOutlined,
  LoginOutlined,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";

// Logo
import A1BetLogo from "@src/assets/logo.png";
import A1BetMobileLogo from "@src/assets/mobile_logo.png";

// Slider
import Banner1 from "@src/assets/images/sliders/banner1.jpg";
import Banner2 from "@src/assets/images/sliders/banner2.jpg";
import Banner3 from "@src/assets/images/sliders/banner3.jpg";
import Banner4 from "@src/assets/images/sliders/banner4.jpg";
import Banner5 from "@src/assets/images/sliders/banner5.jpg";
import Banner6 from "@src/assets/images/sliders/banner6.jpg";

import {
  HeaderAppBar,
  HeaderContainer,
  MobileSignUpButton,
  MobileSubHeaderContainer,
  MobileSubHeaderTab,
  MobileSubHeaderTabs,
  SignInButton,
  SignUpButton,
  SubHeaderContainer,
  SubHeaderTab,
  SubHeaderTabs,
  ThemeSwitch,
} from "./styles";
import { Link } from "@mui/material";
import { MobileMenuList, SubMenu } from "@src/configs/header";

// Slider
import MobilePromotionImage from "@src/assets/images/promotion/mobile_promotion.png";
import { useTheme } from "@mui/material/styles";

// Sign In
import SignInComponent from "@src/views/auth/signin";
import SignUpComponent from "@src/views/auth/signup";
import { Carousel } from "react-responsive-carousel";

const HeaderComponent = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location?.pathname;
  const queryParams = new URLSearchParams(location.search);
  const hashValue = queryParams.get("register");

  const { isAuth, userData, userLogout, sessionCheck } = useAuthStore(
    (state) => state
  );
  const {
    selectSlider,
    setSelectSlider,
    activeTab,
    setActiveTab,
    setTheme,
    setLoading,
  } = useBaseStore((state) => state);
  const [mode, setMode] = useState<string>("light");
  const [signInOpen, setSignInOpen] = useState<boolean>(false);
  const [signUpOpen, setSignUpOpen] = useState<boolean>(false);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [selectedLanguage, setSelectedLanguage] = useState<string>("en");

  useEffect(() => {
    switch (pathname) {
      case "/":
        setActiveTab(0);
        break;
      case "/sport/list":
        setActiveTab(1);
        break;
      case "/live-casino":
        setActiveTab(2);
        break;
      case "/game/list":
        setActiveTab(3);
        break;
      case "/fishing":
        setActiveTab(4);
        break;
      case "/racing":
        setActiveTab(5);
        break;
      case "/lottery":
        setActiveTab(6);
        break;
      case "/app":
        setActiveTab(7);
        break;
      case "/promotion":
        setActiveTab(8);
        break;
    }
  }, [pathname]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  // Mobile
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleMobileMenuOpen = (mobileState: boolean) => {
    setMobileMenuOpen(mobileState);
  };

  const goPage = (url: string) => {
    navigate(url);
    handleMobileMenuOpen(false);
  };
  // Mobile End

  const handleSubTab = (event: any, index: number) => {
    if (index == 2) {
      setSelectSlider(3);
    } else if (index == 3) {
      setSelectSlider(4);
    } else setSelectSlider(index);
    navigate(SubMenu[index].link);
  };

  const handleSwitch = (status: string) => {
    setTheme(status);
    setMode(status);
  };

  const handleSignInDialog = (state: boolean) => {
    setSignInOpen(state);
  };

  const handleSignUpDialog = (state: boolean) => {
    setSignUpOpen(state);
  };

  const logout = useCallback(() => {
    userLogout();
    setAnchorElUser(null);
  }, []);

  useEffect(() => {
    if (hashValue && !userData?.id) {
      setSignUpOpen(true);
    } else setSignUpOpen(false);
  }, []);

  const refreshWallet = async () => {
    setMobileMenuOpen(false);
    setLoading(true);
    await sessionCheck();
    setLoading(false);
  };

  const handlePage = async (url: string) => {
    navigate(url);
    handleCloseUserMenu();
  };

  const handleLanguageChange = (language: string) => {
    setSelectedLanguage(language);
  };

  useEffect(() => {
    if (isAuth) refreshWallet();
  }, [isAuth]);

  if (isMobile) {
    return (
      <>
        <HeaderAppBar position="static">
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            width={"100vw"}
            pl={2}
            pr={2}
          >
            <IconButton
              onClick={() => handleMobileMenuOpen(true)}
              color="primary"
              size="small"
            >
              <MenuIcon />
            </IconButton>
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Toolbar disableGutters>
                <img src={A1BetMobileLogo} />
              </Toolbar>
            </Box>
            {!isAuth ? (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={"4px"}
              >
                <Tooltip title="Sign In">
                  <IconButton onClick={() => handleSignInDialog(true)}>
                    <LoginOutlined sx={{ color: "#1976d2" }} />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={"4px"}
              >
                <Tooltip title="Logout">
                  <IconButton onClick={() => logout()}>
                    <LogoutOutlined sx={{ color: "#1976d2" }} />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
          <MobileSubHeaderContainer display={"flex"} justifyContent={"center"}>
            <MobileSubHeaderTabs
              variant="scrollable"
              aria-label="Sub Mobile Header"
              value={activeTab}
              onChange={handleSubTab}
            >
              {SubMenu.map((item, ind) => (
                <MobileSubHeaderTab
                  key={ind}
                  sx={{ display: ind === 0 ? "none" : "" }}
                  onClick={(event: any) => handleSubTab(event, ind)}
                  label={
                    <Typography
                      noWrap
                      textOverflow={"ellipsis"}
                      color={"text.white"}
                      className="f-12"
                    >
                      {t(item.title)}
                    </Typography>
                  }
                />
              ))}
            </MobileSubHeaderTabs>
          </MobileSubHeaderContainer>
          <Box>
            <Carousel
              autoPlay={true}
              showArrows={false}
              showStatus={false}
              showThumbs={false}
              showIndicators={false}
              selectedItem={selectSlider}
            >
              {[
                Banner1,
                Banner1,
                Banner2,
                Banner3,
                Banner4,
                Banner5,
                Banner6,
              ].map((item) => (
                <Box>
                  <Box component="img" src={item} />
                </Box>
              ))}
            </Carousel>
          </Box>
        </HeaderAppBar>
        <SignInComponent
          signInOpen={signInOpen}
          fullScreen={fullScreen}
          handleSignInDialog={handleSignInDialog}
          handleSignUpDialog={handleSignUpDialog}
        />
        <SignUpComponent
          setSignUpOpen={setSignUpOpen}
          signUpOpen={signUpOpen}
          fullScreen={fullScreen}
          handleSignUpDialog={handleSignUpDialog}
        />
        <SwipeableDrawer
          anchor="right"
          open={mobileMenuOpen}
          onClose={() => handleMobileMenuOpen(false)}
          onOpen={() => handleMobileMenuOpen(true)}
        >
          <Box
            width={"80vw"}
            height={"100vh"}
            sx={{ backgroundColor: theme.palette.background.default }}
          >
            <Box mt={3}></Box>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              flexWrap={"wrap"}
            >
              <img src={A1BetMobileLogo} />
              <Box mb={2} mt={2}>
                <img width={"100%"} src={MobilePromotionImage} />
              </Box>
              {/* {isAuth ? (
                <>
                  <Button
                    onClick={() => navigate("/reference")}
                    size="small"
                    startIcon={<AddCard />}
                  >
                    {Number(Number(userData?.cashback) || 0).toFixed(2)}
                  </Button>
                  <Button size="small" startIcon={<WalletRounded />}>
                    {Number(userData.balance).toFixed(2)}
                  </Button>
                  <IconButton onClick={() => refreshWallet()}>
                    <Refresh />
                  </IconButton>
                  <br />
                  <Button
                    fullWidth
                    sx={{ marginLeft: "5px", marginRight: "5px" }}
                    variant="outlined"
                    onClick={logout}
                    size="small"
                  >
                    {t("Logout")}
                  </Button>
                </>
              ) : (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={"10px"}
                  flexWrap={"wrap"}
                >
                  <SignInButton
                    sx={{ width: "75vw" }}
                    size="small"
                    onClick={() => handleSignInDialog(true)}
                  >
                    {t("Sign In")}
                  </SignInButton>
                  <SignUpButton
                    sx={{ width: "75vw" }}
                    fullWidth
                    size="small"
                    onClick={() => handleSignUpDialog(true)}
                  >
                    {t("Sign Up")}
                  </SignUpButton>
                </Box>
              )} */}
              <Box width={"80vh"}>
                <List dense={true}>
                  {MobileMenuList.map((item, ind) => (
                    <ListItem key={ind}>
                      <ListItemButton onClick={() => goPage(item.link)}>
                        <ListItemIcon
                          sx={{ color: theme.palette.text.secondary }}
                        >
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              textAlign={"end"}
                              color={theme.palette.text.primary}
                              fontWeight={800}
                              fontSize={"14px"}
                              lineHeight={"17.78px"}
                              textTransform={"uppercase"}
                            >
                              {item.title}
                            </Typography>
                          }
                        />
                      </ListItemButton>
                      <Divider
                        variant="middle"
                        sx={{ backgroundColor: theme.palette.text.secondary }}
                        component="li"
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
              <Box display="flex" justifyContent="center">
                <FormControlLabel
                  control={
                    <Box>
                      <ThemeSwitch
                        value={mode === "light" ? true : false}
                        onChange={() =>
                          handleSwitch(mode === "light" ? "dark" : "light")
                        }
                        sx={{ m: 1 }}
                      />
                      <Select
                        value={selectedLanguage} // Assuming you have a state for selected language
                        onChange={(event: SelectChangeEvent) =>
                          handleLanguageChange(event.target.value)
                        }
                        size="small"
                      >
                        <MenuItem value="en">EN</MenuItem>
                        <MenuItem value="cn">CN</MenuItem>
                        <MenuItem value="bm">BM</MenuItem>
                      </Select>
                    </Box>
                  }
                  labelPlacement="start"
                  label=""
                  sx={{ marginLeft: 0 }}
                />
              </Box>
            </Box>
          </Box>
        </SwipeableDrawer>
      </>
    );
  }

  return (
    <>
      <HeaderAppBar position="static">
        <HeaderContainer>
          <Toolbar disableGutters>
            <img className="logo" src={A1BetLogo} />
          </Toolbar>
          <Box>
            {isAuth ? (
              <Box
                className="row"
                display={"flex"}
                justifyContent={"end"}
                alignItems={"center"}
                gap={"10px"}
              >
                <Button
                  onClick={() => navigate("/reference")}
                  size="small"
                  startIcon={<AddCard />}
                >
                  {`RM ${Number(Number(userData?.cashback) || 0).toFixed(2)}`}
                </Button>
                <Button size="small" startIcon={<WalletRounded />}>
                  {`RM ${Number(userData.balance).toFixed(2)}`}
                </Button>
                <IconButton size="small" onClick={() => refreshWallet()}>
                  <Refresh />
                </IconButton>
                <Tooltip title="Open settings">
                  <Button
                    variant="outlined"
                    onClick={handleOpenUserMenu}
                    size="small"
                  >
                    <AccountCircle />
                    <Typography pl={2}>{userData.mobile}</Typography>
                  </Button>
                </Tooltip>
                <Menu
                  sx={{
                    mt: "40px",
                    "& .MuiList-root": {
                      backgroundColor: theme.palette.background.default,
                    },
                  }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={() => handlePage("/profile")}>
                    <ListItemIcon>
                      <Person2Outlined />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography textAlign="center">{t("Account")}</Typography>
                    </ListItemText>
                  </MenuItem>
                  {/* <MenuItem onClick={handleCloseUserMenu}>
                    <ListItemIcon>
                      <WalletOutlined />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography textAlign="center">{t("Wallet")}</Typography>
                    </ListItemText>
                  </MenuItem> */}
                  <MenuItem onClick={logout}>
                    <ListItemIcon>
                      <ExitToAppOutlined />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography textAlign="center">{t("Logout")}</Typography>
                    </ListItemText>
                  </MenuItem>
                </Menu>
              </Box>
            ) : (
              <Box
                className="row"
                display={"flex"}
                justifyContent={"end"}
                alignItems={"center"}
                gap={"10px"}
              >
                <SignInButton
                  size="small"
                  onClick={() => handleSignInDialog(true)}
                >
                  {t("Sign In")}
                </SignInButton>
                <SignUpButton
                  size="small"
                  onClick={() => handleSignUpDialog(true)}
                >
                  {t("Sign Up")}
                </SignUpButton>
              </Box>
            )}
            <Box className="row" display={"flex"}>
              <Stack spacing={4} direction={"row"}>
                {/* <Link
                  href="#"
                  color={"text.default"}
                  underline="none"
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <GroupOutlined />
                  &nbsp;&nbsp;
                  <Typography className="f-12" textTransform={"uppercase"}>
                    {t("affiliate")}
                  </Typography>
                </Link>
                <Link
                  href="#"
                  color={"text.default"}
                  underline="none"
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <CoPresentOutlined />
                  &nbsp;&nbsp;
                  <Typography className="f-12" textTransform={"uppercase"}>
                    {t("affiliate")}
                  </Typography>
                </Link> */}
                <Link
                  href="/reference"
                  color={"text.default"}
                  underline="none"
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <PersonAddOutlined />
                  &nbsp;&nbsp;
                  <Typography className="f-12" textTransform={"uppercase"}>
                    {t("Refer a friend")}
                  </Typography>
                </Link>
              </Stack>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={"8px"}
              >
                <FormControlLabel
                  control={
                    <Box>
                      <ThemeSwitch
                        value={mode === "light" ? true : false}
                        onChange={() =>
                          handleSwitch(mode === "light" ? "dark" : "light")
                        }
                        sx={{ m: 1 }}
                      />
                      <Select
                        value={selectedLanguage} // Assuming you have a state for selected language
                        onChange={(event: SelectChangeEvent) =>
                          handleLanguageChange(event.target.value)
                        }
                        size="small"
                      >
                        <MenuItem value="en">EN</MenuItem>
                        <MenuItem value="cn">CN</MenuItem>
                        <MenuItem value="bm">BM</MenuItem>
                      </Select>
                    </Box>
                  }
                  labelPlacement="start"
                  label=""
                />
              </Box>
            </Box>
          </Box>
        </HeaderContainer>
        <SubHeaderContainer
          sx={{
            height: { lg: "64px", md: "50px", sm: "50px", xs: "50px" },
          }}
        >
          <SubHeaderTabs
            aria-label="Sub Header"
            value={activeTab}
            onChange={handleSubTab}
            centered
          >
            {SubMenu.map((item, ind) => (
              <SubHeaderTab
                key={ind}
                onClick={(event: any) => handleSubTab(event, ind)}
                label={
                  <Typography color={"text.white"} className="f-16">
                    {t(item.title)}
                  </Typography>
                }
                sx={{
                  display: ind === 0 && "none",
                  padding: {
                    lg: "10px 64px 10px 64px",
                    md: "5px 20px 5px 20px",
                    sm: "5px 20px 5px 20px",
                    xs: "5px 20px 5px 20px",
                  },
                  height: { lg: "64px", md: "50px", sm: "50px", xs: "50px" },
                }}
              />
            ))}
          </SubHeaderTabs>
        </SubHeaderContainer>
        <Box>
          <Carousel
            autoPlay={true}
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            showIndicators={false}
            selectedItem={selectSlider}
          >
            {[
              Banner1,
              Banner1,
              Banner2,
              Banner3,
              Banner4,
              Banner5,
              Banner6,
            ].map((item) => (
              <Box>
                <Box component="img" src={item} />
              </Box>
            ))}
          </Carousel>
        </Box>
      </HeaderAppBar>
      <SignInComponent
        signInOpen={signInOpen}
        fullScreen={fullScreen}
        handleSignInDialog={handleSignInDialog}
      />
      <SignUpComponent
        setSignUpOpen={setSignUpOpen}
        hashValue={hashValue}
        signUpOpen={signUpOpen}
        fullScreen={fullScreen}
        handleSignUpDialog={handleSignUpDialog}
      />
    </>
  );
};
export default HeaderComponent;
