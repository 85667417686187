import { Box, Typography, useTheme } from "@mui/material";

const Promotion = () => {
  const theme = useTheme();
  return (
    <>
      <Box my={3} className="waviy">
        {["c", "o", "m", "i", "n", "g", " ", "s", "o", "o", "n"].map(
          (item, ind) => (
            <Typography
              component="span"
              fontWeight={800}
              color={theme.palette.text.secondary}
              sx={{ "--i": ind + 1 }}
            >
              {item}
            </Typography>
          )
        )}
      </Box>
    </>
  );
};

export default Promotion;
