import SwipeableViews from "react-swipeable-views";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  Container,
  IconButton,
  InputBase,
  Paper,
  Tab,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  StyledTabs,
  StyledTab,
  StyledTableCell,
  StyledTableRow,
  StyledButton,
} from "./style";
import useBaseStore from "@src/zustand/base";
import useAuthStore from "@src/zustand/auth";
import useGameStore from "@src/zustand/game";
import { ContentCopy } from "@mui/icons-material";

// Image
import WeChatImage from "@src/assets/images/other/wechat.png";
import WaImage from "@src/assets/images/other/wa.png";
import ReferralImage from "@src/assets/images/other/com.jpg";

import { MAIN } from "@src/configs/index";
import Rebate from "./rebate";
import CommissionTab from "./commission";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const Reference = () => {
  const { setLoading } = useBaseStore((state) => state);
  const { userData, getOTPData } = useAuthStore((state) => state);
  const [value, setValue] = useState(0);
  const [count, setCount] = useState({
    first: 0,
    second: 0,
    share: 0,
    first_commission: 0,
    second_commission: 0,
    total_commission: 0,
  });

  const getUserOTP = async () => {
    setLoading(true);
    const res = await getOTPData({ hash: userData.referral });
    if (res?.data?.status) {
      setCount(res?.data?.data);
    }
    setLoading(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index: number) => {
    setValue(index);
  };
  const copyText = async (text: string) => {
    await navigator.clipboard.writeText(text);
  };

  useEffect(() => {
    if (userData?.referral) getUserOTP();
  }, [userData]);

  return (
    <>
      <Container sx={{ my: 5, px: 0 }}>
        <Box sx={{ bgcolor: "background.paper" }}>
          <AppBar position="static" sx={{ borderRadius: "16px" }}>
            <StyledTabs
              value={value}
              onChange={handleChange}
              aria-label="full width tabs example"
            >
              <StyledTab label="My Info" />
              <StyledTab label="Rebate" />
              <StyledTab label="Commission Level 1" />
              <StyledTab label="Commission Level 2" />
              <StyledTab label="Referral Info" />
            </StyledTabs>
          </AppBar>
          <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
            <TabPanel value={value} index={0}>
              <Container>
                <Box className="row" textAlign={"center"}>
                  <h2>MY QR CODE</h2>
                </Box>
                <Box className="row" display={"flex"} justifyContent={"center"}>
                  <Box
                    width={"300px"}
                    component={"img"}
                    src={`https://api.qrserver.com/v1/create-qr-code/?size=350x350&data=${MAIN.base_url}?register=${userData.referral}`}
                  />
                </Box>
                <Box className="row" textAlign={"center"}>
                  <h4>
                    Referral ID :{" "}
                    {userData?.referral
                      ? `${userData?.referral}`
                      : "You must login at first"}
                  </h4>
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1, width: "300px" }}
                      inputProps={{
                        readOnly: true,
                      }}
                      value={`${MAIN.base_url}?register=${userData.referral}`}
                    />
                    <IconButton
                      type="button"
                      sx={{ p: "10px" }}
                      onClick={() =>
                        copyText(
                          `${MAIN.base_url}?register=${userData.referral}`
                        )
                      }
                    >
                      <ContentCopy />
                    </IconButton>
                  </Box>
                </Box>
                <Box
                  className="row"
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={"8px"}
                >
                  <h4>Share Link : </h4>
                  <a
                    href={`https://api.whatsapp.com/send/?text=I+am+inviting+you+to+join+this+game.+Register+now%3A+${MAIN.base_url}?register=${userData.referral}`}
                  >
                    <Box component={"img"} width={"50px"} src={WaImage} />
                  </a>
                  {/* <Box component={"img"} width={"45px"} src={WeChatImage} /> */}
                </Box>
                <Box
                  sx={{ my: 2 }}
                  className="row"
                  display={"flex"}
                  justifyContent={"center"}
                >
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: "0 !important" }}
                      aria-label="customized table"
                    >
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell align="left">
                            SHARE LINK CLICK
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {count?.share || 0}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell align="left">
                            DOWNLINE LEVEL 1
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {count?.first || 0}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell align="left">
                            DOWNLINE LEVEL 2
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {count?.second || 0}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell align="left">
                            TOTAL TURNOVER LEVEL 1 (COMMISSION)
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {count?.first_commission
                              ? count?.first_commission.toString().length > 4
                                ? Number(count?.first_commission).toFixed(3)
                                : Number(count?.first_commission)
                              : 0}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell align="left">
                            TOTAL TURNOVER LEVEL 2 (COMMISSION)
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {count?.second_commission
                              ? count?.second_commission.toString().length > 4
                                ? Number(count?.second_commission).toFixed(3)
                                : Number(count?.second_commission)
                              : 0}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell align="left">
                            TOTAL COMISSION EARNED
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            {count?.total_commission.toString().length > 4
                              ? Number(count?.total_commission).toFixed(3)
                              : Number(count?.total_commission)}
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Container>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Rebate />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <CommissionTab level={1} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <CommissionTab level={2} />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Container>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box component={"img"} width={"600px"} src={ReferralImage} />
                </Box>
                <Box
                  className="row"
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{ my: 2 }}
                >
                  <Box width={"600px"}>
                    <Typography component={"h6"}>
                      1. Let's say you are A, and you invited B, C and D. B, C
                      and D have MYR100,000 turnover respectively. As B, C and D
                      is your direct downline(Level 1), you will get MYR30,000
                      (MYR300,000 x 10.00%).
                    </Typography>
                    <Typography component={"h6"}>
                      2. B invited E and F, E and F have a total of MYR200,000
                      turnover. As E and F is your indirect downline(Level 2),
                      you will get MYR2,000 (MYR200,000 x 1.00%)..
                    </Typography>
                    <Typography component={"h6"}>
                      3. So in total, A get MYR32,000 (MYR30,000 + MYR2,000).
                    </Typography>
                  </Box>
                </Box>
                <Box
                  className="row"
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <TableContainer sx={{ maxWidth: 600 }} component={Paper}>
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="left">LEVEL</StyledTableCell>
                          <StyledTableCell align="left">
                            COMMISSION RATIO
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell
                            align="left"
                            component="th"
                            scope="row"
                          >
                            1
                          </StyledTableCell>
                          <StyledTableCell align="left">3.00 %</StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell
                            align="left"
                            component="th"
                            scope="row"
                          >
                            2
                          </StyledTableCell>
                          <StyledTableCell align="left">1.00 %</StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Container>
            </TabPanel>
          </SwipeableViews>
        </Box>
      </Container>
    </>
  );
};

export default Reference;
