import APIAbstract from "./APIAbstract";
import { MAIN } from "../configs";

class AuthApi extends APIAbstract {
  // Authentication part
  addPlayer(data: any) {
    return this.post("/v1/auth/signup", data);
  }
  signInUser(data: any) {
    return this.post("/v1/auth/signin", data);
  }
  userLogout() {
    return this.delete("/v1/auth/logout");
  }
  sessionCheck() {
    return this.get("/v1/auth/sessionCheck");
  }
  handleOTP(data: string) {
    return this.post("/v1/auth/handleOTP", data);
  }
  getOTPData(data: any) {
    return this.post("/v1/auth/getOTPData", data);
  }
  getCommission(data: any) {
    return this.post("/v1/auth/getCommission", data);
  }
  getRebate(data: any) {
    return this.post("/v1/auth/getRebate", data);
  }
  changePassword(data: any) {
    return this.post("/v1/auth/changePassword", data);
  }
}

const AuthClass = new AuthApi(`${MAIN.server_url}`);

export default AuthClass;
