import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import useBaseStore from "@src/zustand/base";
import useSportStore from "@src/zustand/sport";
import useGameStore from "@src/zustand/game";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Image from "@src/components/image";
import { SportGridItem, SportGridText } from "./style";

const SportList = () => {
  const { setLoading } = useBaseStore((state) => state);
  const { getThreeSingGameURL, getSabaGameURL, getRCB988GameURL } =
    useSportStore((state) => state);
  const { getGameURL, getGameProviders } = useGameStore((state) => state);
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [list, setList] = useState([]);

  const openGame = async (provider: string, code: string) => {
    setLoading(true);
    const res = await getGameURL({
      provider: provider,
      code: code,
      type: "sport",
      isMobile,
    });
    if (res?.status) {
      window.open(
        res?.data,
        "GameOpen",
        "width=800,height=600,left=100,top=100"
      );
    }
    setLoading(false);
  };

  const goGameList = async (url: string) => {
    setLoading(true);
    if (url.includes("3sing")) {
      openGame("3sing", "3sing");
    } else if (url.includes("saba")) {
      openGame("saba", "saba");
    } else navigate(url);
    setLoading(false);
  };

  const init = async () => {
    const res = await getGameProviders({ type: "sport" });
    if (res?.status) {
      setList(res.data);
    } else {
      setList([]);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Box className="row" my={5}>
        <Container maxWidth="lg">
          <Grid
            container
            display={"flex"}
            spacing={3}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {list.map((item: any, ind: number) =>
              item ? (
                <SportGridItem
                  key={ind}
                  item
                  position={"relative"}
                  md={3}
                  xs={4}
                  sm={4}
                >
                  <Box
                    onClick={() => goGameList(`/sport/${item.key}`)}
                    component={"img"}
                    src={`/provider/${item.key}.jpg`}
                    borderRadius={"10px"}
                    sx={{
                      "&:hover": { filter: "blur(5px)" },
                      cursor: "pointer",
                      width: "100%",
                    }}
                  />
                </SportGridItem>
              ) : (
                <Skeleton variant="rounded" width={238} height={289} />
              )
            )}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default SportList;
