import FootballImage from "@src/assets/images/sport/football.png";
import CasinoImage from "@src/assets/images/sport/casino.png";
import RacingImage from "@src/assets/images/sport/racing.jpg";

export const getPerPage = (): number => {
  if (window.innerWidth >= 900) {
    return 30;
  } else if (window.innerWidth >= 600) {
    return 30;
  } else {
    return 30;
  }
};

export const categoryOptions = [
  {
    value: "sport",
    label: "Sportsbook",
    types: "sport",
    img: FootballImage,
    delay: 2300,
  },
  {
    value: "live",
    label: "Live Casino",
    types: "live",
    img: CasinoImage,
    delay: 2500,
  },
  {
    value: "slot",
    label: "E-Games",
    types: "slots",
    img: CasinoImage,
    delay: 2900,
  },
  {
    value: "fishing",
    label: "Fishing",
    types: "fishing",
    img: CasinoImage,
    delay: 2700,
  },
  {
    value: "racing",
    label: "Racing",
    types: "racing",
    img: RacingImage,
    delay: 3000,
  },
  // {
  //   value: "lottery",
  //   label: "lottery",
  //   types: "lottery",
  //   img: CasinoImage,
  //   delay: 2700,
  // },
  // {
  //   value: "App",
  //   label: "App",
  //   types: "App",
  //   img: CasinoImage,
  //   delay: 2700,
  // },
];
