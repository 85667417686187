import { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StyledTableCell } from "./style";
import useAuthStore from "@src/zustand/auth";
import useBaseStore from "@src/zustand/base";

interface FilterTypes {
  date: DateRange<Dayjs>;
}

const CommissionTab = ({ level }: any) => {
  const { setLoading } = useBaseStore((state) => state);
  const { userData, getCommission } = useAuthStore((state) => state);
  const [commissionList, setCommissionList] = useState([]);
  const [filter, setFilter] = useState<FilterTypes>({
    date: [dayjs().startOf("day"), dayjs()],
  });

  const init = async () => {
    setLoading(true);
    const res = await getCommission({ hash: userData.referral, filter, level });
    if (res?.status) {
      setCommissionList(res?.data);
    }
    setLoading(false);
  };

  const maskPhoneNumber = (phoneNumber: string) => {
    const str = phoneNumber.toString();
    return `${str.substring(0, 2)}XXXXX${str.substring(7)}`;
  };

  useEffect(() => {
    init();
  }, [filter]);
  return (
    <>
      <Box className="row" sx={{ mb: 2 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateRangePicker
            localeText={{
              start: "From",
              end: "To",
            }}
            value={filter.date}
            onChange={(e) => setFilter({ ...filter, date: e })}
          />
        </LocalizationProvider>
      </Box>
      <Box className="row">
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: "0 !important" }}
            aria-label="customized table"
          >
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Account</StyledTableCell>
                <StyledTableCell align="left">Vendor</StyledTableCell>
                <StyledTableCell align="left">Turnover</StyledTableCell>
                <StyledTableCell align="left">Rebate</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!commissionList?.length && (
                <Typography sx={{ m: 2 }} component={"h4"}>
                  No Data
                </Typography>
              )}
              {commissionList.map((item: any, ind: number) => {
                return (
                  <TableRow key={ind}>
                    <StyledTableCell align="left">
                      {maskPhoneNumber(item?.mobile)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {item?.provider}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {item.bet.toString().length > 4
                        ? Number(Math.abs(item.bet)).toFixed(3)
                        : Number(Math.abs(item.bet))}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {item.rebate.toString().length > 4
                        ? Number(Math.abs(item.rebate)).toFixed(3)
                        : Number(Math.abs(item.rebate))}
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default CommissionTab;
