import { PaletteMode } from "@mui/material";

export const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode !== "dark"
      ? {
          // palette values for light mode
          background: {
            default: "#F0F6FF",
            primary: "#5EC6F1",
            paper:
              "linear-gradient(180deg, #F7FBFF 0%, #FFFFFF 28.65%, #E7EEFE 68.23%, #FFFFFF 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF)",
            footer: "#E3E3E3",
          },
          text: {
            default: "#000000",
            primary: "#5EC6F1",
            white: "#FFFFFF",
            secondary: "#244091",
            promoTitle: "#244091",
            featureTitle: "#244091",
            featureContent: "#000000",
          },
          button: {
            background: "#E49E24",
            primary: "#FFFFFF",
            width: "128px",
            height: "32px",
          },
          border: {
            default: "#FFFFFF",
          },
        }
      : {
          // palette values for dark mode
          background: {
            default: "#001431",
            primary: "#5EC6F1",
            paper:
              "linear-gradient(180deg, #244091 0%, #001E74 100%), linear-gradient(0deg, #A3C8FF, #A3C8FF)",
            footer: "#13376C",
          },
          text: {
            default: "#FFFFFF",
            primary: "#5EC6F1",
            white: "#FFFFFF",
            secondary: "#FFFFFF",
            promoTitle: "#76869E",
            featureTitle: "#A3C8FF",
            featureContent: "#FFFFFF",
          },
          button: {
            background: "#E49E24",
            primary: "#FFFFFF",
            width: "128px",
            height: "32px",
          },
          border: {
            default: "#A3C8FF",
          },
        }),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1025,
      lg: 1450,
      xl: 1600,
    },
  },
});
