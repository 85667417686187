import React, { FC, ReactNode } from "react";
import { Box } from "@mui/material";
import { keyframes } from "@emotion/react";

const marqueeAnimation = keyframes`
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
`;

interface MarqueeProps {
  children: ReactNode;
  speed?: number;
}

const Marquee: FC<MarqueeProps> = ({ children, speed = 20 }) => {
  return (
    <Box
      sx={{
        overflow: "hidden",
        whiteSpace: "nowrap",
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "inline-block",
          animation: `${marqueeAnimation} ${speed}s linear infinite`,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Marquee;
