import React from "react";
import { Box, Typography, useTheme } from "@mui/material";

const Announcement = () => {
  const theme = useTheme();
  return (
    <>
      <Box my={3}>
        <Typography color={theme.palette.text.secondary} component="h2">
          Comming Soon !
        </Typography>
      </Box>
    </>
  );
};

export default Announcement;
