import React from "react";
import { ToastContainer } from "react-toastify";

// Dark & Light Mode
import { ThemeProvider, createTheme } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";

// CSS
import "./App.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-toastify/dist/ReactToastify.css";

// Config
import { getDesignTokens } from "@src/configs/color";

// Store
import useBaseStore from "@src/zustand/base";

// Router
import AppRoutes from "@src/routes";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

// Loading
import Loading from "@src/components/loading";
import AuthProvider from "./context/auth";

const router = createBrowserRouter(AppRoutes);

const App = () => {
  const { theme } = useBaseStore((state) => state);

  // Update the theme only if the mode changes
  const customeTheme = React.useMemo(
    () => createTheme(getDesignTokens(theme)),
    [theme]
  );
  return (
    <>
      <AuthProvider>
        <ThemeProvider theme={customeTheme}>
          <CssBaseline />
          <Loading />
          <RouterProvider router={router} />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </ThemeProvider>
      </AuthProvider>
    </>
  );
};

export default App;
