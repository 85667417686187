import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PageTracking: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag("config", "UA-273390698-1", {
        page_path: location.pathname + location.search,
      });
    }
  }, [location]);

  return null;
};

export default PageTracking;
