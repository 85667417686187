import {
  CreditCard,
  History,
  Notifications,
  Person,
} from "@mui/icons-material";
import useAuthStore from "@src/zustand/auth";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { StyledTab, StyledTabPanel, StyledTabs } from "./style";
import NotificationTab from "./notification";
import DepositTab from "./deposit";
import WithdrawTab from "./withdraw";
import GameHistoryTab from "./gamehistory";
import TransactionHistoryTab from "./transfer";
import ProfileTab from "./profile";
import { useNavigate, useSearchParams } from "react-router-dom";

const Profile = () => {
  const navigate = useNavigate();
  const [searchParms] = useSearchParams();
  const page = searchParms.get("page");
  const { isAuth } = useAuthStore((state) => state);
  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const init = async () => {
    if (!isAuth) navigate("/");
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    switch (page) {
      case "deposit":
        setTabIndex(1);
        break;
      case "withdraw":
        setTabIndex(2);
        break;
    }
  }, [page]);

  return (
    <Box sx={{ my: 5, mx: 0.5 }}>
      <StyledTabs value={tabIndex} onChange={handleChange}>
        <StyledTab
          iconPosition="start"
          color={"text.default"}
          icon={<Person />}
          label="Profile"
        />
        <StyledTab
          iconPosition="start"
          color={"text.default"}
          icon={<CreditCard />}
          label="Deposit"
        />
        <StyledTab
          iconPosition="start"
          color={"text.default"}
          icon={<CreditCard />}
          label="Withdraw"
        />
        <StyledTab
          iconPosition="start"
          color={"text.default"}
          icon={<History />}
          label="Game History"
        />
        <StyledTab
          iconPosition="start"
          color={"text.default"}
          icon={<History />}
          label="Transfer"
        />
        <StyledTab
          iconPosition="start"
          color={"text.default"}
          icon={<Notifications />}
          label="Notifications"
        />
      </StyledTabs>
      <StyledTabPanel value={tabIndex} index={0}>
        <ProfileTab setTabIndex={setTabIndex} />
      </StyledTabPanel>
      <StyledTabPanel value={tabIndex} index={1}>
        <DepositTab />
      </StyledTabPanel>
      <StyledTabPanel value={tabIndex} index={2}>
        <WithdrawTab />
      </StyledTabPanel>
      <StyledTabPanel value={tabIndex} index={3}>
        <GameHistoryTab />
      </StyledTabPanel>
      <StyledTabPanel value={tabIndex} index={4}>
        <TransactionHistoryTab />
      </StyledTabPanel>
      <StyledTabPanel value={tabIndex} index={5}>
        <NotificationTab />
      </StyledTabPanel>
    </Box>
  );
};

export default Profile;
