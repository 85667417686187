import {
  AccountCircle,
  CloseOutlined,
  Lock,
  PhoneAndroid,
  SendOutlined,
  SendToMobile,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

// Logo
import A1BetLogo from "@src/assets/logo.png";
import SignInImage from "@src/assets/images/auth/signin.png";
import { toast } from "react-toastify";

import useAuthStore from "@src/zustand/auth";
import useBaseStore from "@src/zustand/base";
import { useLocation } from "react-router-dom";

const initialSignUpData = {
  name: "",
  password: "",
  confirm_password: "",
  mobile: "",
  // verify: "",
};

const SignUp = ({
  fullScreen,
  setSignUpOpen,
  signUpOpen,
  handleSignUpDialog,
}: any) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hashValue = queryParams.get("register");
  const { setLoading } = useBaseStore((state) => state);
  const { addPlayer, handleOTP, signInUser } = useAuthStore((state) => state);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [signUpData, setSignUpData] = useState(initialSignUpData);
  const [showPassword, setShowPassword] = useState(false);
  const [confirmShowPassword, setConfirmShowPassword] = useState(false);
  const [verifyStatus, setVerifyStatus] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickConfirmShowPassword = () =>
    setConfirmShowPassword((show) => !show);

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleSignUpData = (key: string, value: string) => {
    setSignUpData({
      ...signUpData,
      [key]: value,
    });
  };

  const handleMobileVerify = async () => {
    if (
      !signUpData.mobile ||
      signUpData.mobile.length < 6 ||
      signUpData.mobile.length > 15
    ) {
      toast.error("Mobile Number is in an invalid format.");
      return;
    } else {
      setVerifyStatus(true);
      setTimeout(() => {
        setVerifyStatus(false);
      }, 60 * 1000);
      const res = await handleOTP({ mobile: signUpData.mobile });
      if (res.data) {
        console.log("successful sent");
      } else {
        toast.error("Failed to send SMS.");
      }
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (
      !signUpData.mobile ||
      // !signUpData.verify ||
      !signUpData.password ||
      !signUpData.confirm_password
    ) {
      return toast.error("Please Input All Fields.");
    } else if (signUpData.password !== signUpData.confirm_password) {
      return toast.error("Password didn't match correctly.");
    } else {
      setSignUpOpen(false);
      setLoading(true);
      const sendData: any = signUpData;
      sendData.hash = hashValue || "";
      const res = await addPlayer(sendData);
      if (res?.status) {
        await signInUser({
          mobile: `60${signUpData.mobile}`,
          password: signUpData.password,
        });
      }
      setSignUpData(initialSignUpData);
      setLoading(false);
    }
  };

  if (isMobile)
    return (
      <>
        <Dialog
          fullScreen={fullScreen}
          open={signUpOpen}
          onClose={handleSignUpDialog}
          aria-labelledby="responsive-dialog-title"
          sx={{
            "& .MuiDialogContent-root ": {
              backgroundColor: theme.palette.background.default,
            },
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => handleSignUpDialog(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseOutlined />
          </IconButton>
          <DialogContent>
            <form onSubmit={handleSubmit}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                height={"85vh"}
              >
                <Box
                  gap={"8px"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box>
                    <Box
                      mb={2}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <img src={A1BetLogo} />
                    </Box>
                    <Box
                      mb={2}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">
                          Mobile Number
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          startAdornment={
                            <InputAdornment position="start">
                              +60
                            </InputAdornment>
                          }
                          // endAdornment={
                          //   <InputAdornment position="end">
                          //     <LoadingButton
                          //       loading={verifyStatus}
                          //       variant="outlined"
                          //       size="small"
                          //       loadingPosition="start"
                          //       startIcon={<SendOutlined />}
                          //       onClick={handleMobileVerify}
                          //     >
                          //       Send Code
                          //     </LoadingButton>
                          //   </InputAdornment>
                          // }
                          label="Mobile Number"
                          value={signUpData?.mobile}
                          onChange={(e) =>
                            handleSignUpData("mobile", e.target.value)
                          }
                        />
                      </FormControl>
                    </Box>
                    {/* <Box
                      mb={2}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">
                          Username
                        </InputLabel>
                        <OutlinedInput
                          startAdornment={
                            <InputAdornment position="start">
                              <AccountCircle />
                            </InputAdornment>
                          }
                          id="outlined-adornment-username"
                          type="text"
                          label="Username"
                          value={signUpData?.name}
                          onChange={(e) =>
                            handleSignUpData("name", e.target.value)
                          }
                        />
                      </FormControl>
                    </Box> */}
                    <Box
                      mb={2}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">
                          Password
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={showPassword ? "text" : "password"}
                          startAdornment={
                            <InputAdornment position="start">
                              <Lock />
                            </InputAdornment>
                          }
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                          value={signUpData?.password}
                          onChange={(e) =>
                            handleSignUpData("password", e.target.value)
                          }
                        />
                      </FormControl>
                    </Box>
                    <Box
                      mb={2}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">
                          Confirm Password
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={confirmShowPassword ? "text" : "password"}
                          startAdornment={
                            <InputAdornment position="start">
                              <Lock />
                            </InputAdornment>
                          }
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickConfirmShowPassword}
                                edge="end"
                              >
                                {confirmShowPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Confirm Password"
                          value={signUpData?.confirm_password}
                          onChange={(e) =>
                            handleSignUpData("confirm_password", e.target.value)
                          }
                        />
                      </FormControl>
                    </Box>

                    {/* <Box
                      mb={2}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">
                          Verify Code
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          startAdornment={
                            <InputAdornment position="start">
                              <PhoneAndroid />
                            </InputAdornment>
                          }
                          label="Verify Code"
                          value={signUpData?.verify}
                          onChange={(e) =>
                            handleSignUpData("verify", e.target.value)
                          }
                        />
                      </FormControl>
                    </Box> */}
                    <Box
                      ml={1}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Button
                        variant="outlined"
                        fullWidth
                        color="primary"
                        type="submit"
                      >
                        {t("Sign Up")}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </form>
          </DialogContent>
        </Dialog>
      </>
    );

  return (
    <Dialog
      fullScreen={fullScreen}
      open={signUpOpen}
      onClose={handleSignUpDialog}
      aria-labelledby="responsive-dialog-title"
      sx={{
        "& .MuiPaper-root": {
          backgroundColor: theme.palette.background.default,
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={() => handleSignUpDialog(false)}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseOutlined />
      </IconButton>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Box display={"flex"}>
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <img src={SignInImage} />
            </Box>
            <Box
              gap={"8px"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Box>
                <Box
                  mb={2}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <img src={A1BetLogo} />
                </Box>
                <Box
                  mb={2}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Mobile Number
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type="number"
                      startAdornment={
                        <InputAdornment position="start">+60</InputAdornment>
                      }
                      // endAdornment={
                      //   <InputAdornment position="end">
                      //     <LoadingButton
                      //       loading={verifyStatus}
                      //       variant="outlined"
                      //       size="small"
                      //       loadingPosition="start"
                      //       startIcon={<SendOutlined />}
                      //       onClick={handleMobileVerify}
                      //     >
                      //       Send Code
                      //     </LoadingButton>
                      //   </InputAdornment>
                      // }
                      label="Mobile Number"
                      value={signUpData?.mobile}
                      onChange={(e) =>
                        handleSignUpData("mobile", e.target.value)
                      }
                    />
                  </FormControl>
                </Box>
                {/* <Box
                  mb={2}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Username
                    </InputLabel>
                    <OutlinedInput
                      startAdornment={
                        <InputAdornment position="start">
                          <AccountCircle />
                        </InputAdornment>
                      }
                      id="outlined-adornment-username"
                      type="text"
                      label="Username"
                      value={signUpData?.name}
                      onChange={(e) => handleSignUpData("name", e.target.value)}
                    />
                  </FormControl>
                </Box> */}
                <Box
                  mb={2}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      startAdornment={
                        <InputAdornment position="start">
                          <Lock />
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                      value={signUpData?.password}
                      onChange={(e) =>
                        handleSignUpData("password", e.target.value)
                      }
                    />
                  </FormControl>
                </Box>
                <Box
                  mb={2}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Confirm Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={confirmShowPassword ? "text" : "password"}
                      startAdornment={
                        <InputAdornment position="start">
                          <Lock />
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickConfirmShowPassword}
                            edge="end"
                          >
                            {confirmShowPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Confirm Password"
                      value={signUpData?.confirm_password}
                      onChange={(e) =>
                        handleSignUpData("confirm_password", e.target.value)
                      }
                    />
                  </FormControl>
                </Box>

                {/* <Box
                  mb={2}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Verify Code
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      startAdornment={
                        <InputAdornment position="start">
                          <PhoneAndroid />
                        </InputAdornment>
                      }
                      label="Verify Code"
                      value={signUpData?.verify}
                      onChange={(e) =>
                        handleSignUpData("verify", e.target.value)
                      }
                    />
                  </FormControl>
                </Box> */}
                <Box
                  m={1}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Button
                    variant="outlined"
                    fullWidth
                    color="primary"
                    type="submit"
                  >
                    {t("Sign Up")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default SignUp;
