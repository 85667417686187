import { PaletteMode } from "@mui/material";
import { create } from "zustand";

interface BaseStore {
  theme: PaletteMode;
  loading: boolean;
  activeTab: number;
  selectSlider: number;
  setSelectSlider: any;
  setActiveTab: any;
  setLoading: any;
  setTheme: any;
}

const useStore = create<BaseStore>((set) => ({
  theme: "light",
  loading: false,
  activeTab: 0,
  selectSlider: 0,
  setSelectSlider: (index: number) => set({ selectSlider: index }),
  setActiveTab: (index: number) => set({ activeTab: index }),
  setLoading: (state: boolean) => set({ loading: state }),
  setTheme: (state: PaletteMode) => set({ theme: state }),
}));

export default useStore;
