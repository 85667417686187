import useBaseStore from "@src/zustand/base";

const Loading = () => {
  const { loading } = useBaseStore((state) => state);
  return loading ? (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "#000000",
        position: "absolute",
        zIndex: 100,
        opacity: 0.7,
      }}
    >
      <div className="loading-ring">
        Loading
        <span></span>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Loading;
