import { create } from "zustand";
import SportAPI from "@src/apis/sport";

interface SportStore {
  getThreeSingGameURL: any;
  getSabaGameURL: any;
  getRCB988GameURL: any;
}

const useStore = create<SportStore>((set: any) => ({
  getThreeSingGameURL: async (data = {}) => {
    const res = await SportAPI.getThreeSingGameURL(data);
    return res;
  },
  getSabaGameURL: async (data = {}) => {
    const res = await SportAPI.getSabaGameURL(data);
    return res.data;
  },

  // RCB988 
  getRCB988GameURL: async (data = {}) => {
    const res = await SportAPI.getRCB988GameURL(data);
    return res.data;
  },
}));

export default useStore;
